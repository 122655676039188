export default [
  {
    /**
     * 功能描述: 分页查询还款列表
     * http://10.222.160.160:8080/#/view/r2jWmn8G
     */
    name: 'queryRepayPageList',
    method: 'post',
    url: '/admin/repay/pagelist ',
  },
  {
    /**
     * 功能描述: 还款记录详情
     * http://10.222.160.160:8080/#/view/32QleWz0
     */
    name: 'queryRepayDetial',
    method: 'post',
    url: '/admin/repay/detail',
  },
  {
    /**
     * 功能描述: 还款单 贷款列表
     * http://10.222.160.160:8080/#/view/qXBoNWzE
     */
    name: 'queryFinanceList',
    method: 'post',
    url: '/admin/repay/financelist',
  },
]
