import enLocale from '@jdt/find/lib/locale/lang/en'
export default {
  ...enLocale,
  lang: 'Language',
  locale: 'English',
  exit: 'Exit',
  menu: {
    home: 'Home',
    business: 'Business Manage',
    businessAccess: 'Access Apply Manage',
    businessAccessDetail: 'Access Apply Detail',
    businessLoan: 'Loan Manage',
    businessLoanDetail: 'Loan Apply Detail',
    businessRepay: 'Repay Manage',
    businessRepayDetail: 'Repay Apply Detail',
    businessContract: 'Contract Manage',
    customer: 'Customer Service Manage',
    enterpriseMgmt: 'Enterprise Manage',
    enterpriseInfo: 'Enterprise Info',
    enterpriseInfoDetail: 'Enterprise Info Detail',
    enterpriseRealInfo: 'Enterprise Real Info',
    enterpriseRealInfoDetail: 'Enterprise Real Info Detail',
    customerInfo: 'Customer Info Manage',
    advance: 'Advance',
    advanceAccount: 'Receive Account Manage',
    white: 'White Magage',
    whiteWhite: 'White Magage',
    core: 'Core Company Manage',
    coreOperator: 'Operator Manage',
    coreRole: 'Role Manage',
    coreDepartment: 'Department Manage',
    corePost: 'Post Manage',
    receivableFinancing: 'Receivable Financing',
    receivableAccounts: 'Receivable Accounts',
    capital: 'Capital',
    capitalDetail: 'Capital Detail',
    enterprise: 'Core Enterprise',
    enterpriseDetail: 'Core Enterprise Detail',
    holidayManagement: 'Holiday Management',
  },
  search: {
    reset: 'Reset',
    query: 'Query',
    export: 'Export',
    open: 'open',
    close: 'Put away',
    enter: 'enter',
    select: 'select',
    createdDate: 'Create Date',
    access: {
      productCode: 'Product Code',
      userID: 'User ID',
      customerName: 'Customer Name',
      applyType: 'Apply Type',
      applyNo: 'Apply No',
      captialApplyNo: 'Apply Captial No',
      currentNode: 'Current Node',
      nodeStatus: 'Node Status',
      applyTime: 'Apply Time',
      approveTime: 'Approve Time',
      creditTime: 'Credit Time',
    },
    loan: {
      productCode: 'Product Code',
      userID: 'User ID',
      customerName: 'Customer Name',
      applyNo: 'Loan Apply No',
      captialApplyNo: 'Apply Captial No',
      loanNo: 'Loan No',
      loanStatus: 'Loan Status',
      loanApplyTime: 'Loan Apply Time',
      loanSuccessTime: 'Loan Success Time',
      loanEndTime: 'Loan End Time',
    },
    repay: {
      productCode: 'Product Code',
      userID: 'User ID',
      customerName: 'Customer Name',
      applyNo: 'Repay Apply No',
      captialApplyNo: 'Apply Captial No',
      payWay: 'Repay Type',
      repayStatus: 'Repay Status',
      repayApplyTime: 'Repay Apply Time',
      repayFinishTime: 'Repay Finish Time',
    },
    contract: {
      productCode: 'Product Code',
      scene: 'Scene',
      customerName: 'Customer Name',
      applyNo: 'Apply No',
      signStatus: 'Sign Status',
      signApplyTime: 'Sign Apply Time',
      signFinishTime: 'Sign Finish Time',
    },
    account: {
      productCode: 'Product Code',
      principalPart: 'Principal Part Name',
      principalType: 'Principal Part Type',
      receiveAccount: 'Receive Account',
      status: 'Status',
      createTime: 'Create Time',
      updateTime: 'Uodate Time',
    },
    info: {
      productCode: 'Product Code',
      userID: 'User ID',
      userType: 'User Type',
      customerName: 'Customer Name',
      contactPhone: 'Contact Phone',
      isProductOpened: 'Is Product Opened',
    },
    white: {
      productName: 'Product Name',
      customerName: 'Customer Name',
      certNo: 'Cert No',
      inviteCode: 'Invite Code',
      cellPhone: 'Cell Phone',
      state: 'State',
      createTime: 'Create Time',
    },
    operator: {
      operatorNo: 'Operator No',
      operatorName: 'Operator Name',
      operatorPhone: 'Operator Phone',
    },
    receivable: {
      productNo: 'Product number',
      receivableAccountsNo: 'Receivable Accounts number',
      receivableAccountsState: 'receivable Accounts State',
      receiveDateStart: 'receive Date Start',
      receiveDateStop: 'receive Date Stop',
      financingFlag: 'financing Flag',
      sellerNo: 'seller number',
      sellerName: 'seller Name',
      buyerName: 'buyer Name',
    },
    capital: {
      userNo: 'User number',
      userName: 'User name',
      realState: 'Capital State',
      startDate: 'Date Start',
      endDate: 'Date End',
    },
    coreEnterprise: {
      userNo: 'Enterprise number',
      userName: 'Enterprise name',
      personCardNo: 'PersonCard number',
      state: 'Enterprise State',
      startDate: 'Date Start',
      endDate: 'Date End',
    },
  },
  list: {
    accessTitle: 'Access List',
    loanTitle: 'Loan List',
    repayTitle: 'Repay List',
    contractTitle: 'Contract List',
    accountTitle: 'Account List',
    infoTitle: 'Customer List',
    capitalTitle: 'Capital List',
    coreEnterpriseTitle: 'Enterprise List',
    whiteTitle: 'White List',
    operatorTitle: 'Operator List',
    departmentTitle: 'Department List',
    roleTitle: 'Role List',
    postTitle: 'Post List',
    noData: 'No Data',
    operate: 'Operate',

    access: {
      productCode: 'Product Code',
      userID: 'User ID',
      customerName: 'Customer Name',
      applyType: 'Apply Type',
      applyNo: 'Apply No',
      captialApplyNo: 'Apply Captial No',
      currentNode: 'Current Node',
      nodeStatus: 'Node Status',
      applyTime: 'Apply Time',
      approveTime: 'Approve Time',
      creditSuccessDate: 'Credit Success Date',
    },
    loan: {
      productCode: 'Product Code',
      userID: 'User ID',
      customerName: 'Customer Name',
      applyNo: 'Apply No',
      captialApplyNo: 'Apply Captial No',
      loanStatus: 'Loan Status',
      applyTime: 'Apply Time',
      successTime: 'Success Time',
      dueTime: 'Due Time',
      loanNo: 'LoanNo',
    },
    repay: {
      productCode: 'Product Code',
      applyNo: 'Apply No',
      captialApplyNo: 'Apply Captial No',
      userID: 'User ID',
      customerName: 'Customer Name',
      repayAmount: 'Repay Amount(YUAN,',
      payWay: 'Repay Type',
      repayStatus: 'Repay Status',
      repayApplyTime: 'Repay Apply Time',
      repayFinishTime: 'Repay Finish Time',
    },
    contract: {
      productCode: 'Product Code',
      applyNo: 'Apply No',
      customerName: 'Customer Name',
      scene: 'Secne',
      signStatus: 'Sign Status',
      signApplyTime: 'Sign Apply Time',
      signFinishTime: 'Sign Finish Time',
    },
    info: {
      productCode: 'Product Code',
      userID: 'User ID',
      userType: 'User Type',
      customerName: 'Customer Name',
      contactPhone: 'Contact Phone',
      isProductOpened: 'Is Product Opened',
    },
    account: {
      productCode: 'Product Code',
      subjectName: 'Subject Name',
      subjectType: 'Subject Type',
      account: 'Account',
      state: 'State',
      createTime: 'Create Time',
      updateTime: 'Update Time',
    },
    white: {
      productCode: 'Product Code',
      customerName: 'Customer Name',
      customerType: 'Customer Type',
      certNo: 'Cert No',
      inviteCode: 'Invite Code',
      cellPhone: 'Cell Phone',
      certType: 'Cert Type',
      contactName: 'Contact Name',
      inviteFrequency: 'Invite Frequency',
      state: 'State',
      createdDate: 'Create Date',
    },
    receivable: {
      subjectNo: 'subject number',
      productCode: 'product number',
      productName: 'product name',
      sellerCode: 'seller number',
      sellerName: 'seller name',
      buyerName: 'buyer name',
      validAmount: 'valid amount',
      expireDate: 'expire date',
      source: 'source',
      state: 'receivable state',
      payState: 'payState',
      financeNo: 'finance number',
      financeAmount: 'finance amount',
    },
    capital: {
      id: 'Serial no.',
      userNo: 'User number',
      userName: 'User name',
      contactName: 'Contact name',
      contactCellphone: 'contact cellphone',
      realState: 'Capital State',
      createdDate: 'Create Date',
    },
    coreEnterprise: {
      id: 'Serial no.',
      userNo: 'Enterprise Number',
      userName: 'Enterprise Name',
      personCardNo: 'PersonCard Number',
      contactName: 'Contact Name',
      contactCellphone: 'Contact Cellphone',
      state: 'Enterprise State',
      realState: 'Certificate Status',
      createdDate: 'Create Date',
    },
  },
  detail: {
    view: 'View',
    check: 'Check',
    download: 'Download',
    edit: 'Edit',
    add: 'Add',
    send: 'Send',
    invalid: 'invalid',
    header: {
      access: {
        basicInfo: 'Project Basic Info',
        userInfo: 'User Info',
        guarInfo: 'Guarantee Info',
        quotaInfo: 'Quota Info',
        rateInfo: 'Rate Info',
      },
      loan: {
        applyInfo: 'Apply Info',
        userInfo: 'User Info',
        loanInfo: 'Loan Info',
        repayPlan: 'Repay Plan',
        repayDetail: 'Repay Detail',
      },
      repay: {
        applyInfo: 'Apply Info',
        userInfo: 'User Info',
        repayDetail: 'Repay Detail',
      },
      contract: {
        applyInfo: 'Apply Info',
        userInfo: 'User Info',
        repayDetail: 'Repay Detail',
      },
    },
    info: {
      access: {
        applyNo: 'Apply No',
        captialApplyNo: 'Captial Apply No',
        productCode: 'Product Code',
        currentNode: 'Current Node',
        creditType: 'Credit Type',
        productOpenTime: 'Product Open Time',
        creditEndTime: 'Credit End Time',
        contract: 'Contract',
        approveStatus: 'Approve Status',
        rejectReason: 'Reject Reason',
        userID: 'User ID',
        userType: 'User Type',
        companyName: 'Comopany Name',
        creditCode: 'Unified Social Credit Code',
        legalName: 'Legal Name',
        legalCardID: 'Legal Card ID',
        legalPhone: 'Legal Phone',
        contactName: 'Contact Name',
        contactPhone: 'Contact Phone',
        adminPhone: 'Admin Phone',
        address: 'Address',
        productCreditTime: 'Product Open Time（Credit Success Time）',
        guarType: 'Guarantee Type',
        guarWay: 'Guarantee Way',
        guarName: 'Guarantee Name',
        guarCardID: 'Guarantee Card ID',
        guarQuota: 'Guarantee Quota',
        creditTotalQuota: 'Credit Total Quota',
        creditFixedQuota: 'Credit Fixed Quota',
        creditTempQuota: 'Credit Temp Quota',
        creditValidateQuota: 'Credit Validate Quota',
        period1: 'One Period',
        period3: 'Three Period',
        period6: 'Nine Period',
        period12: 'Twelve Period',
      },
      loan: {
        applyNo: 'Apply No',
        captialApplyNo: 'Captial Apply No',
        productName: 'product Name',
        currentNode: 'Current Node',
        creditType: 'Credit Type',
        productOpenTime: 'Product Open Time',
        creditEndTime: 'Credit End Time',
        contract: 'Contract',
        approveStatus: 'Approve Status',
        rejectReason: 'Reject Reason',
        userID: 'User ID',
        userType: 'User Type',
        companyName: 'Comopany Name',
        creditCode: 'Unified Social Credit Code',
        legalName: 'Legal Name',
        legalCardID: 'Legal Card ID',
        legalPhone: 'Legal Phone',
        contactName: 'Contact Name',
        contactPhone: 'Contact Phone',
        adminPhone: 'Admin Phone',
        address: 'Address',
        productCreditTime: 'Product Open Time（Credit Success Time）',
        guarType: 'Guarantee Type',
        guarWay: 'Guarantee Way',
        guarName: 'Guarantee Name',
        guarCardID: 'Guarantee Card ID',
        guarQuota: 'Guarantee Quota',
        creditTotalQuota: 'Credit Total Quota',
        creditFixedQuota: 'Credit Fixed Quota',
        creditTempQuota: 'Credit Temp Quota',
        creditValidateQuota: 'Credit Validate Quota',
        period1: 'One Period',
        period3: 'Three Period',
        period6: 'Nine Period',
        period12: 'Twelve Period',
        productCode: 'Product Code',
        payeeAccount: ' Payee Account',
        payee: 'Payee',
        applyTime: 'ApplyTime',
      },
      white: {
        single: 'SINGLE',
        batch: 'BATCH',
        productName: 'productName',
        customerName: 'customerName',
        certNo: 'Cert No',
        certType: 'Cert Type',
        contactName: 'Contact Name',
        cellPhone: 'Cell Phone',
      },
    },
  },
  // 枚举
  enum: {
    access: {
      // 申请类型
      applyType: {
        ADD: 'ADD',
        EXPIRED: 'EXPIRED',
        LEGAL_CHANGE: 'LEGAL_CHANGE',
        ID_CARD_EXPIRED: 'ID_CARD_EXPIRED',
        CERT_EXPIRED: 'CERT_EXPIRED',
        INCREASE: 'INCREASE',
      },
      // 当前节点
      currentNode: {
        E_STANDARD_REAL_NAME: 'E_STANDARD_REAL_NAME',
        ADDITIONAL_INFO: 'ADDITIONAL_INFO',
        SIGN_CONTRACT: 'SIGN_CONTRACT',
        ACCESS_AUDIT: 'ACCESS_AUDIT',
      },
    },
    loan: {
      // 放款状态
      loanStatus: {
        PROCESS: 'PROCESS',
        SUCCESS: 'SUCCESS',
        FAIl: 'FAIl',
      },
    },
    info: {
      userType: {
        COMPANY: 'COMPANY',
        PERSON: 'PERSON',
      },
      state: {
        OPENING: 'OPENING',
        OPENED: 'OPENED',
      },
    },
    account: {
      subjectType: {
        ENTERPRISE: 'ENTERPRISE',
        PERSON: 'PERSON',
      },
      state: {
        PROCESS: 'PROCESS',
        SUCCESS: 'SUCCESS',
        REJECT: 'REJECT',
      },
    },
    white: {
      state: {
        VALID: 'VALID',
        USED: 'USED',
        EXPIRED: 'EXPIRED',
        DELETED: 'DELETED',
        NOTINVITE: 'NOTINVITE',
        HASINVITE: 'HASINVITE',
      },
      customerType: {
        COMPANY: 'COMPANY',
        PERSON: 'PERSON',
      },
      certType: {
        IDCARD: 'IDCARD',
        USCC: 'USCC',
      },
    },
    capital: {
      realState: {
        PROCESS: 'PROCESS',
        SUCCESS: 'SUCCESS',
        FAIL: 'FAIL',
        EXPIRED: 'EXPIRED',
      },
      personCertType: {
        IDCARD: 'IDCARD',
        USCC: 'USCC',
      },
    },
    coreEnterprise: {
      realState: {
        PROCESS: 'PROCESS',
        SUCCESS: 'SUCCESS',
        FAIL: 'FAIL',
        EXPIRED: 'EXPIRED',
      },
      state: {
        ENABLE: 'ENABLE',
        DISABLE: 'DISABLE',
      },
    },
  },
}
