/*
  api文档地址：http://mock.jdfmgt.com/interface?projectId=6210989baf658e3b672dcb93
*/
export default [
  {
    /* 分页查询风险名单 */
    name: 'riskListPageList',
    method: 'post',
    url: '/admin/riskList/pageList',
  },
  {
    /* 风险名单的添加 */
    name: 'riskListCreate',
    method: 'post',
    url: '/admin/riskList/create',
  },
  {
    /* 	风险名单修改 */
    name: 'riskListUpdate',
    method: 'post',
    url: '/admin/riskList/update',
  },
  {
    /* 查询风险名单详情 */
    name: 'riskListDetail',
    method: 'post',
    url: '/admin/riskList/detail',
  },
]
