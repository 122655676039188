import store from '@/store'

function check(el, binding) {
  const { value } = binding

  const funcMap = store.getters && store.getters.menuTree.funcMap

  if (value) {
    const hasPermission = (funcMap || {})[value]

    if (!hasPermission) {
      el.remove()
    }
  }
}

export default {
  inserted(el, binding) {
    check(el, binding)
  },
  update(el, binding) {
    check(el, binding)
  },
}
