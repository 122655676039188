export default [
  // 	资金转账列表查询
  {
    name: 'queryTreasuryTransferList',
    method: 'post',
    url: '/admin/fund/pagelist',
  },
  // 	转账记录详情查询
  {
    name: 'queryTreasuryTransferDetail',
    method: 'post',
    url: '/admin/fund/detail',
  },
  // 资金通道
  {
    name: 'queryTreasuryChannel',
    method: 'post',
    url: '/admin/channel/list',
  },
  // 人工认缴接口
  {
    name: 'postTreasuryPayment',
    method: 'post',
    url: '/admin/fund/payment',
  },
  // 资金通道列表
  {
    name: 'queryPassagewayList',
    method: 'post',
    url: '/admin/channel/pagelist',
  },
  // 资金通道修改记录
  {
    name: 'queryPassHistoryPageList',
    method: 'post',
    url: '/admin/channel/historyPageList',
  },
  // 资金通道详情
  {
    name: 'queryPassWayDetail',
    method: 'post',
    url: '/admin/channel/detail',
  },
  // 资金通道详情
  {
    name: 'queryPassWayDetail',
    method: 'post',
    url: '/admin/channel/detail',
  },
  // 资金通道修改
  {
    name: 'queryPassWayUpdate',
    method: 'post',
    url: '/admin/channel/update',
  },
]
