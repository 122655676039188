import colorPlate from './color/colorPlate'
import tinycolor from './color/tinycolor'

export default function getThemeVars(defaultColor = {}) {
  let mainColor = {
    ...{
      'primary-color': '#2c68ff',
      'success-color': '#6db247',
      'warning-color': '#ffb630',
      'danger-color': '#f15151',
      'info-color': '#a1a5af',
    },
    ...defaultColor,
  }
  let extendColor = {}
  let opacityPrimaryColor = {}
  for (let i = 1; i <= 7; i++) {
    Object.keys(mainColor).forEach(m => {
      extendColor[`${m}-${i}`] = colorPlate(mainColor[m], i)
    })
  }

  for (let i = 0; i <= 100; i += 5) {
    opacityPrimaryColor[`primary-color-opacity-${i}`] = tinycolor(mainColor['primary-color'])
      .setAlpha(i / 100)
      .toHex8String()
  }
  let baseColor = {
    'white-color': '#fff',
    'black-color': '#000',
    'transparent-color': 'transparent',
    'fill-base': '#fff',
    'font-color': '#000',
  }
  let mixColor = {
    'color-text-primary': tinycolor.mix2(baseColor['font-color'], baseColor['transparent-color'], 85).toHex8String(),
    'color-text-regular': tinycolor.mix2(baseColor['font-color'], baseColor['transparent-color'], 65).toHex8String(),
    'color-text-secondary': tinycolor.mix2(baseColor['font-color'], baseColor['transparent-color'], 45).toHex8String(),
    'color-text-placeholder': tinycolor
      .mix2(baseColor['font-color'], baseColor['transparent-color'], 25)
      .toHex8String(),
    'border-color-base': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 15).toHexString(),
    'background-color-dark': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 25).toHexString(),
    'background-color-base': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 10).toHexString(),
    'hover-background-color': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 6).toHexString(),
    'disabled-fill-base': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 4).toHexString(),
    'background-color-light': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 2).toHexString(),
    'loading-mask': tinycolor.mix2(baseColor['fill-base'], baseColor['transparent-color'], 90).toHex8String(),
    'carousel-arrow-hover-background': tinycolor
      .mix2(baseColor['font-color'], baseColor['transparent-color'], 20)
      .toHex8String(),
    'backtop-background-color': tinycolor
      .mix2(baseColor['font-color'], baseColor['transparent-color'], 60)
      .toHex8String(),
    'backtop-hover-background-color': tinycolor
      .mix2(baseColor['font-color'], baseColor['transparent-color'], 40)
      .toHex8String(),
    'tooltip-border-color': tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 8).toHexString(),
    'slider-button-hover-color': tinycolor.mix2(mainColor['primary-color'], baseColor['black-color'], 97).toHexString(),
    'dropdown-caret-button-before-color': tinycolor
      .mix2(baseColor['fill-base'], baseColor['transparent-color'], 50)
      .toHex8String(),
    'dropdown-button-default-before-color': tinycolor
      .mix2(
        tinycolor.mix2(baseColor['font-color'], baseColor['fill-base'], 15).toHexString(),
        baseColor['transparent-color'],
        50,
      )
      .toHexString(),
    'tree-highlight-background-color': tinycolor
      .mix2(baseColor['white-color'], baseColor['primary-color'], 92)
      .toHexString(),
  }

  let shadowColor = {
    'shadow-color-1': tinycolor.mix2(baseColor['black-color'], baseColor['transparent-color'], 2).toHex8String(),
    'shadow-color-2': tinycolor.mix2(baseColor['black-color'], baseColor['transparent-color'], 4).toHex8String(),
  }

  let fadeColor = {
    'scrollbar-background-color': tinycolor(mixColor['color-text-secondary'])
      .setAlpha(0.2)
      .toHex8String(),
    'scrollbar-hover-background-color': tinycolor(mixColor['color-text-secondary'])
      .setAlpha(0.4)
      .toHex8String(),
    'carousel-indicator-out-color': tinycolor(mixColor['color-text-secondary'])
      .setAlpha(0.1)
      .toHex8String(),
    'carousel-button-background-color': tinycolor(mixColor['fill-base'])
      .setAlpha(0.4)
      .toHex8String(),
    'button-border-color': tinycolor(mixColor['white-color'])
      .setAlpha(0.5)
      .toHex8String(),
  }
  return {
    ...mainColor,
    ...extendColor,
    ...opacityPrimaryColor,
    ...baseColor,
    ...mixColor,
    ...shadowColor,
    ...fadeColor,
  }
}
