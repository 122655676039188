import zhLocale from '@jdt/find/lib/locale/lang/zh-CN'
export default {
  ...zhLocale,
  lang: '语言',
  locale: '中文',
  exit: '退出',
  menu: {
    home: '首页',
    business: '业务运营管理',
    businessAccess: '准入申请管理',
    businessAccessDetail: '准入申请详情',
    businessLoan: '放款申请管理',
    businessLoanDetail: '放款申请详情',
    businessRepay: '还款申请管理',
    businessRepayDetail: '还款申请详情',
    businessContract: '合同管理',
    businessContractDetail: '合同详情',
    enterpriseMgmt: '企业管理',
    enterpriseInfo: '企业信息',
    enterpriseInfoDetail: '企业信息详情',
    enterpriseRealInfo: '企业实名信息',
    enterpriseRealInfoDetail: '企业实名信息详情',
    enterpriseHistoryRealInfoDetail: '历史实名信息详情',
    potentialMgmt: '潜客管理',
    potentialList: '潜客列表',
    potentialRoll: '潜客名单',
    potentialListDetail: '潜客列表详情',
    potentialUpdateListDetail: '更新名单详情',
    subjectMatter: '标的物管理',
    subjectReceivable: '应收账款管理',
    subjectReceivableDetail: '应收账款详情',
    subjectReceivableDetailHistory: '历史订单记录',
    subjectPurchase: '采购订单',
    subjectPurchaseDetail: '采购订单详情',
    subjectPurchaseDetailHistory: '历史订单记录',
    customer: '客户服务管理',
    customerInfo: '客户信息管理',
    customerInfoDetail: '客户详情',
    customerFinance: '融资客户',
    advance: '采购融资',
    advanceAccount: '收款账户管理',
    advanceAccountDetail: '收款账户详情',
    white: '白名单管理',
    whiteWhite: '白名单管理',
    whiteWhiteDetail: '白名单详情',
    whiteBatch: '批量邀请管理',
    whiteBatchDetail: '批量邀请详情',
    whiteBatchAdd: '新增批量邀请',
    core: '系统权限管理',
    coreOperator: '操作员管理',
    coreRole: '角色管理',
    coreRoleDetail: '角色详情',
    coreDepartment: '部门管理',
    corePost: '岗位管理',
    receivableAccounts: '应收账款管理',
    receivableFinancing: '应收融资',
    fancingScale: '融资比例',
    fancingScaleSo: '融资比例查询',
    product: '产品管理',
    productProduct: '产品信息',
    productProductDetail: '产品详情',
    productProductEdit: '修改产品',
    productProductAdd: '新增产品',
    creditManagement: '用信管理',
    creditOrder: '融资订单',
    creditApply: '融资申请单',
    creditRepay: '还款申请单',
    customerManagement: '融资客户',
    capital: '资金方',
    capitalDetail: '资方详情',
    enterprise: '核心企业',
    enterpriseDetail: '核心企业详情',
    account: '账户管理',
    accountCapita: '融资客户账户',
    coreEnterprises: '核心企业账户',
    customerFinanceDetail: '融资客户详情',
    creditApplyDetail: '融资申请单详情',
    financing: '融资客户账户',
    financeCusDetail: '账户详情',
    accountCompanyInfo: '企业账户信息',
    accountDetail: '账户详情',
    book: '台账管理',
    bookLoan: '放款台账',
    bookLoanDetail: '放款台账详情',
    bookPlan: '还款计划',
    bookRepay: '还款台账',
    bookRepayDetail: '还款台账详情',
    loanManage: '贷后管理',
    loanProject: '项目管理',
    loanDanger: '风险结果',
    taskManagement: '待办任务管理',
    taskManagementDetail: '待办任务详情',
    taskManagementExecute: '待办任务审批',
    treasury: '资金服务',
    treasuryTransfer: '资金转账',
    treasuryThought: '资金通道名称',
    treasuryTransferDetail: '资金转账详情',
    treasuryThoughtDetail: '资金通道详情',
    holidayManagement: '节假日管理',
    creditFlow: '信用流转',
    orderLine: '开单额度管理',
    orderLineDetail: '开单额度详情',
    riskList: '风险名单',
    blacklist: '风险名单',
    riskListDeatil: '风险名单详情',
    notice: '公告管理',
    noticeNotice: '公告信息',
    noticeNoticeDetail: '公告详情',
  },
  header: {
    basicInfo: '基本信息',
    projectBasicInfo: '项目基本信息',
    userInfo: '用户信息',
    guarInfo: '担保信息',
    quotaInfo: '额度信息',
    rateInfo: '利率信息',
    applyInfo: '申请信息',
    loanInfo: '融资详情',
    repayPlan: '还款计划',
    repayDetail: '还款详情',
    repayWriteOffDetail: '还款冲销明细',
    signNodeInfo: '签约节点',
    inviteInfo: '邀请记录',
    inviteListInfo: '邀请名单',
    menuInfo: '功能权限',
    roleInfo: '角色信息',
    productInfo: '产品权限',
    usccInfo: '营业执照信息',
    legalInfo: '法定代表人信息',
    productOpenInfo: '产品开通信息',
    productUseInfo: '产品使用信息',
    authenticationInfo: '认证信息',
    enterpriseBasicInfo: '企业基本信息',
    operatorInfo: '经办人信息',
    annexInfo: '证件附件信息',
    financeApplyInfo: '融资申请信息',
    cumtomerInfo: '客户信息',
    approveInfo: '用信审核信息',
    counterpartyInfo: '交易对手信息',
    feeDetailInfo: '费项详情',
    accounntInfo: '账户信息',
    changeInfo: '变更记录',
    detailInfo: '明细信息',
    modifyRecordInfo: '修改记录',
    relationProductInfo: '关联产品',
    accountTipe: '如计划作为收款账户使用，请填写“开户支行”、“联行号“及“银行简码”信息',
    transferBasicInfo: '基本信息详情',
    transferOtherInfo: '其它转账详情',
    transferOtherAdvice: '认缴意见',
    passagewayBasicInfo: '基本详情',
    passagewayUpdateRecord: '修改记录',
    updateRecords: '更新记录',
    noticeInfo: '公告内容',
    accountInfo: '账户信息',
  },
  common: {
    ok: '确认',
    add: '新增',
    find: '找回',
    edit: '修改',
    view: '详情',
    open: '展开',
    send: '发送邀请码',
    check: '查看',
    reset: '重置',
    query: '查询',
    close: '收起',
    enter: '请输入',
    enter2: '请输入(非必填)',
    select: '请选择',
    selectDate: '选择日期',
    export: '导出列表',
    delete: '删除',
    cancel: '取消',
    change: '变更',
    confirm: '确认',
    invalid: '作废',
    execute: '处理',
    setting: '设置',
    download: '下载',
    validate: '验证手机',
    switchOpen: '启用',
    switchClose: '禁用',
    switchRelease: '发布',
    singleAdd: '单个新增',
    batchAdd: '批量新增',
    confirmAdd: '确认新增',
    confirmEdit: '确认修改',
    currentProcesser: '当前处理人',
    startPlaceholder: '开始时间',
    rangeSeparator: '至',
    endPlaceholder: '结束时间',
    operate: '操作',
    noNull: '不能为空',
    frozen: '冻结',
    release: '解冻',
  },
  business: {
    /**产品、用户、客户、企业、合同*/
    productCode: '产品编号',
    productName: '产品名称',
    userID: '用户ID',
    userCode: '用户编号',
    userType: '用户类型',
    userName: '用户名称',
    customerCode: '客户编号',
    customerType: '客户类型',
    customerName: '客户名称',
    customerOuterCode: '客户外部编号',
    customerProductRole: '客户产品角色',
    fundCode: '资方编号',
    fundName: '资方名称',
    coreCode: '核心企业编号',
    enterpriseCode: '企业编号',
    enterpriseType: '企业类型',
    enterpriseName: '企业名称',
    coreEnterpriseCode: '核心企业编号',
    coreEnterpriseName: '核心企业名称',
    coreEnterpriseOuterCode: '核心企业外部编号',
    outerCustomerCode: '客户外部编号',
    accessCode: '准入编号',

    /**公共 */
    noticeTitle: '公告标题',
    isTop: '是否置顶',
    noticeColumn: '发布栏目',

    /**银行账号相关 */
    bankName: '开户行',
    bankCode: '银行简码',
    branchName: '开户支行',
    bankUnionCode: '联行号',
    accountNo: '账户编号',
    area: '所在地区',
    provinceName: '所在省/市',
    cityName: '所在市',
    districtName: '所在区/县',
    accountCode: '账户号码',
    accountName: '账户名称',
    accountType: '账户类型',
    payeeAccount: '收款账号',
    principalPart: '收款主体名称',
    principalType: '主体类型',
    accountPurpose: '账户用途',

    /**时间相关 */
    topValidateDate: '置顶有效期至',
    expireDate: '到期时间',
    expireDateRange: '到期起止时间',
    createdDate: '创建时间',
    publishDate: '发布时间',
    createdDateRange: '创建起止时间',
    quotaStartDate: '额度生效日',
    quotaEndDate: '额度到期日',
    updateDate: '更新时间',
    editDate: '修改时间',
    updateDateRange: '更新起止时间',
    applyDate: '申请时间',
    applyDateRange: '申请起止时间',
    loanApplyDate: '融资申请时间',
    loanDateRange: '融资起止时间',
    loanApplyDateRange: '融资申请起止时间',
    loanExpireDate: '融资到期时间',
    loanExpireDateRange: '融资到期起止时间',
    repayData: '还款时间',
    repayApplyDateRange: '还款申请起止时间',
    repayFinishDateRange: '还款完成起止时间',
    validDateRange: '有效期起止时间',
    approveDate: '审核时间',
    approveFinishDateRange: '审核起止时间',
    creaditSuccessDateRange: '授信成功起止时间',
    signApplyDate: '签约发起时间',
    signFinishDate: '签约完成时间',
    signApplyDateRange: '签约发起起止时间',
    signFinishDateRange: '签约完成起止时间',
    acceptDate: '接收时间',
    acceptDateRange: '接收起止时间',
    stageDateRange: '分期起止时间',
    finishDateRange: '完成起止时间',
    financeFinishDate: '融资完成时间',
    financeFinishDateRange: '融资完成起止时间',
    financeDate: '融资时间',
    financeBeginDate: '融资开始日',
    financeExprieDate: '融资到期日',
    clearDate: '结清时间',
    repayDate: '还款日期',
    transferBeginDate: '执行时间',
    transferFinishedDate: '完成时间',
    stageStartDate: '分期开始时间',
    realExpireDate: '实名有效期至',
    creditExpireDate: '授信有效期至',
    payFinishData: '放款完成时间',

    /**状态相关 */
    state: '状态',
    timeoutState: '超时状态',
    processState: '处理状态',
    signState: '签约状态',
    nodeState: '节点状态',
    realState: '实名认证状态',
    loanState: '融资状态',
    payState: '放款状态',
    financeState: '融资状态',
    repayState: '还款状态',
    stageState: '分期状态',
    clearState: '结清状态',
    writeoffState: '核销状态',
    accountState: '账户状态',
    orderState: '订单状态',
    receivableState: '应收账款状态',
    signatoryState: '签署状态',
    publishState: '发布状态',

    /**贷还款相关 */
    loanCode: '融资编号',
    repayCode: '还款编号',
    repayType: '还款类型',
    repayMode: '还款方式',
    repayWay: '还款通道',
    applyCode: '申请编号',
    loanApplyCode: '放款申请编号',
    loanAmount: '放款金额（元）',
    repayApplyCode: '还款申请编号',
    fundApplyCode: '资方申请编号',
    financeApplyCode: '融资申请单号',
    financeOrderCode: '融资定单编号',
    financeNode: '融资节点',
    financeFlag: '融资标识',
    financeRate: '融资比例',
    canFinanceAmount: '最大可融资金额(元)',
    financeAmount: '融资金额(元)',
    financeApplyAmount: '融资申请金额（元）',
    financeTotalAmount: '订单总金额(元)',
    financeOrderAmount: '融资订单金额(元)',
    financeDuration: '融资期限',
    financeApplyDuration: '融资申请期限',
    quotaBeforeFinance: '融资前额度(元)',
    repayAmount: '还款金额(元)',
    amount: '金额(元)',
    yearRate: '年化利率(%)',
    realRate: '实际利率',
    remainPrincipalAmount: '未还本金(元)',
    remainFee: '未还息费(元)',
    stageBillCode: '分期单号',

    auditResult: '风控审核结果',
    auditCode: '策略返回码',
    auditDesc: '审核结果描述',

    dishonestExecutor: '失信执行人',
    punishmentNum: '两年行政处罚的次数',
    blackList: '风险名单',
    multipleScore: '企业评分/等级',

    /**实名相关 */
    realChannel: '实名认证渠道',
    certCode: '证件号码',
    customerCertCode: '客户证件号码',
    certType: '证件类型',
    enterpriseCertType: '企业证件类型',

    /**信用流转 */
    quotaShared: '是否可共用',
    quotaNo: '额度编号',
    outQuotaNo: '外部额度编号',
    creditSource: '授信来源',
    creditAmt: '额度总额(元)',
    usedAmt: '已用额度(元)',
    availableAmt: '可用额度(元)',
    quotaUse: '额度使用方式',

    /**实名相关结束 */

    productModel: '业务模式',
    applyType: '申请类型',
    currentNode: '当前节点',
    createType: '创建方式',
    remark: '备注说明',
    scene: '场景',

    /**权限相关开始 */
    dept: '部门',
    deptCode: '部门编号',
    deptName: '部门名称',
    deptType: '部门类型',
    deptOrder: '部门排序',
    parentDept: '上级部门',
    principalName: '负责人姓名',
    principalPhone: '负责人手机号',
    principalEmail: '负责人邮箱',

    station: '岗位',
    stationCode: '岗位编号',
    stationName: '岗位名称',
    stationOrder: '岗位排序',

    role: '角色',
    roleCode: '角色编号',
    roleName: '角色名称',
    roleOrder: '角色排序',
    functionNum: '功能权限（个）',
    productNum: '产品权限（个）',

    operator: '操作员',
    operatorInfoEdit: '操作员信息修改',
    operatorCode: '操作员编号',
    operatorName: '操作员姓名',
    operatorName2: '操作人',
    operatorPhone: '操作员手机号',
    operatorEmail: '操作员邮箱',
    operatorCertCode: '操作员证件号',
    isResetPassword: '是否重置密码',
    phoneNo: '手机号',
    choiceRole: '选择角色',
    /**权限相关结束 */

    /**白名单 */
    inviteCode: '邀请码',
    latestInviteCode: '最新邀请码',
    inviteBatchNo: '批次号',
    totalCounts: '总数量',
    waitProcessCounts: '待发送数量',
    failCounts: '发送失败数量',
    successCounts: '发送成功数量',
    inviteFrequency: '邀请次数',

    inviteCodeUseState: '使用状态',
    inviteCodeSendState: '发送状态',
    /**白名单结束 */

    /**联系人相关 */
    contactPhone: '联系电话',
    contactName: '联系人姓名',
    cellPhone: '手机号码',

    urgentContactName: '紧急联系人姓名',
    urgentContactPhone: '紧急联系人手机号码',
    adminCellPhone: '管理员手机号',
    address: '联系地址',
    /**联系人相关 */

    isProductOpened: '是否已开通',
    productCreditTime: '产品开通时间（授信成功时间）',

    /**法人相关开始 */
    uscc: '统一社会信用代码',
    legalName: '法定代表人姓名',
    legalCardID: '法定代表人证件号码',
    legalCellPhone: '法定代表人手机号码',
    /**法人相关结束 */

    /**应收账款相关 */
    sellerCode: '卖方编号',
    sellerName: '卖方名称',
    buyerName: '买方名称',
    receivableBillCode: '应收账款编号',
    receivableBillState: '应收账款状态',
    receivableAmount: '应收账款金额(元)',
    /**应收账款结束 */

    /**合同相关 */
    signScene: '签署场景',
    contractFileNo: '合同编号',
    contractName: '合同类型',
    signDate: '签署时间',
    contractState: '合同状态',
    /**合同相关结束 */

    /**资金相关 */
    transferApplyNo: '转账申请编号',
    capitalChannelCode: '资金通道编号',
    capitalChannelName: '资金通道名称',
    payAccountName: '出金账户名称',
    payAccountCode: '出金银行卡号',
    payBankName: '出金开户行',
    payBankBranchName: '出金开户行支行',
    receiveAccountName: '入金账户名称',
    receiveAccountCode: '入金银行卡号',
    receiveBankName: '入金开户行',
    receiveBankBranchName: '入金开户行支行',
    transferItem: '转账名目',
    transferAmount: '转账金额(元)',
    businessBillCode: '业务单号',
    /**资金相关 结束*/

    /**审批流 */
    taskCode: '流程编号',
    flowName: '流程名称',
    submitor: '提交方',
    bizType: '业务类型',
    bizCode: '业务编号',
    /**审批流 结束 */

    /**标的物-采购 */
    purchaseOrderCode: '采购订单编号',
    orderPayState: '订单支付状态',
    counterpartyName: '交易对手名称',
    /**标的物-采购 结束 */

    /**预警相关 */
    timeoutThreshold: '超时阈值（s）',
    configParam: '配置信息',
    modifiedContent: '修改内容',

    /**合同 */
    signType: '签署类型',
    contractSignInfo: '合同签署信息',
    signatoryInfo: '签署方信息',
    signatoryCustomerCode: '签署方客户编码',
    signatoryCustomerName: '签署方名称',
    signatoryOrder: '签署顺序',

    /**登录 */

    smsVerifyCode: '短信验证码',
    newPassword: '新登录密码',

    Name: '姓名',
    personId: '身份证号',

    /**台账相关 */
    repayNo: '还款单号',
    loanNo: '贷款编号',
    loanStageLedger: '贷款状态',
    loanBeginDate: '贷款开始日',
    loanEndDate: '贷款到期日',
    loanDateRangeBook: '贷款时间',
    loanTerm: '分期数',
    periodCount: '分期数',
    stageTimeEnd: '分期结束时间',
    executeDayRate: '执行日利率',
    fineDayRate: '罚息利率',
    actualRepayAmount: '实际还款金额(元)',
    actualNormalPrincipallAmount: '还正常期本金(元)',
    actualOverduePrincipalAmount: '还逾期本金(元)',
    actualNormalInterestAmount: '还正常利息(元)',
    actualOverdueInterestAmount: '还逾期利息(元)',
    actualPrincipalFineAmount: '还本金罚息(元)',
    actualInterestFineAmount: '还利息罚息(元)',
    repayBalance: '还款余额(元)',
    subscribe: '认缴',

    shouldTotalAmount: '应还总金额(元)',
    periodCode: '期号',
    shouldNormalPrincipallAmount: '应还正常期本金(元)',
    shouldOverduePrincipalAmount: '应还逾期本金(元)',
    shouldNormalInterestAmount: '应还正常期利息(元)',
    shouldOverdueInterestAmount: '应还逾期利息(元)',
    shouldPrincipalFineAmount: '应还本金罚息(元)',

    remainTotalAmount: '未还总金额(元)',
    repaidTotalAmount: '已还总金额(元)',
    remainFeeAmount: '未还息费(元)',
    repaidPrincipalAmount: '已还本金(元)',
    repaidFeeAmount: '已还息费(元)',
    repaidOverduePrincipalAmount: '已还逾期本金(元)',
    repaidNormalPrincipalAmount: '已还正常期本金(元)',
    repaidNormalInterestAmount: '已还正常期利息(元)',
    repaidInterestFineAmount: '已还利息罚息(元)',
    repaidOverdueInterestAmount: '已还逾期利息(元)',
    repaidPrincipalFineAmount: '已还本金罚息(元)',
    remainNormalPrincipalAmount: '应还正常期本金(元)',
    remainOverduePrincipalAmount: '应还逾期本金(元)',
    remainOverdueInterestAmount: '应还逾期利息(元)',
    remainNormalInterestAmount: '应还正常期利息(元)',
    remainPrincipalFineAmount: '应还本金罚息(元)',
    remainInterestFineAmount: '应还利息罚息(元)',
    dueDate: '到期日',
    remainNormalPrincipallAmount: '应还正常期本金(元)',
    /* 风险管理 */
    companyName: '客户名称',
    companyType: '客户类型',
    uscc1: '证件号码',
    modifiedDate: '时间',
    operatorErp: '操作人',

    /*中信虚户*/
    accountDetails: '账户明细',
    accountStatus: '账号状态',
    tradeType: '交易类型',
    tradeTime: '交易时间',
    tradeDate: '交易日期',
    hostFlw: '柜员交易号',
    hostSeq: '交易序号',
    oppAccNo: '对方账户',
    oppAccName: '对方账户名称',
    companyNo: '客户号',
    customerNo: '企业证件号码',

    /**标的物 */
    bizNo: '标的物编号',
  },
  list: {
    treasuryTransferTitle: '转账列表',
    treasuryThoughtTitle: '通道列表',
    bookLoanTitle: '放款台账列表',
    bookRepayTitle: '还款台账列表',
    bookPlanTitle: '还款计划列表',
    accessTitle: '准入列表',
    loanTitle: '放款列表',
    repayTitle: '还款列表',
    contractTitle: '合同列表',
    recordTitle: '记录列表',
    accountTitle: '账户列表',
    accountDetailed: '明细列表',
    financeCusAccountTitle: '账户列表',
    infoTitle: '客户列表',
    capitalTitle: '资方列表',
    coreEnterpriseTitle: '企业列表',
    enterpriseInfoTitle: '企业列表',
    potentialListTitle: '潜客名单列表',
    fancingScaleTitle: '申请列表',
    whiteTitle: '白名单列表',
    whiteBatch: '批量邀请列表',
    operatorTitle: '操作员列表',
    departmentTitle: '部门列表',
    roleTitle: '角色列表',
    postTitle: '岗位列表',
    receivableTitle: '应收账款列表',
    subjectReceivableTitle: '账款列表',
    subjectPurchaseTitle: '订单列表',
    orderLineTitle: '开单额度列表',
    creditOrderTitle: '订单列表',
    financingApplyTitle: '申请列表',
    productTitle: '产品列表',
    noData: '暂无满足条件的数据内容',
    querybookLoan: '查询结果列表',
    taskTitle: '待办列表',
    noticeTitle: '公告管理列表',
    blacklist: '风险名单列表',
  },
  enum: {
    // loanManage: {
    //   monitorType: {
    //     LOCAL: '本地',
    //     REMOTE: '远程',
    //   },
    //   level: {
    //     0: '红色',
    //     1: '通过',
    //     2: '橙色',
    //     3: '黄色',
    //   },
    // },
    user: {
      // userType: {
      //   COMPANY: '企业',
      //   PERSON: '个人',
      // },
    },
    financingApply: {
      // financeDuration: {
      //   DAY: '天',
      //   WEEK: '周',
      //   MONTH: '月',
      //   QUARTER: '季度',
      //   YEAR: '年',
      // },
      // auditResult: {
      //   audit_in: '审核中',
      //   audit_pass: '审核通过',
      //   audit_reject: '审核拒绝',
      //   audit_return: '审核驳回',
      //   audit_error: '审核异常',
      // },
      // financeState: {
      //   PROCESS: '申请审核中',
      //   INIT: '待审核',
      //   SUCCESS: '审核成功',
      //   FAIL: '审核失败',
      //   REJECT: '已驳回',
      // },
      // payState: {
      //   INIT: '未放款',
      //   PROCESS: '放款审核中',
      //   SUCCESS: '放款成功',
      //   FAIL: '放款失败',
      //   REJECT: '已经驳回',
      // },
      // repayMode: {
      //   INT_AND_CAP_CLEAR: '一次性还本付息',
      //   INT_1_CAP_2: '按月付息，到期还本',
      //   INT_1_CAP_2_FIX: '按月付息，到期还本',
      //   EQUAL_INSTALLMENT: '等本等息',
      //   AVE_CAP_PLUS_INT: '等额本息',
      //   AVE_CAPITAL: '等额本金',
      //   LSBQ: '利随本清',
      //   XXHB: '先息后本',
      //   OTHER: '其他',
      //   ARJT: '按日均摊',
      //   DEJX: '等额减息',
      // },
    },
    financingApplyDetail: {
      // financeState: {
      //   PROCESS: '申请审核中',
      //   INIT: '待审核',
      //   SUCCESS: '审核成功',
      //   FAIL: '审核失败',
      //   REJECT: '已驳回',
      // },
      // payState: {
      //   INIT: '未放款',
      //   PROCESS: '放款审核中',
      //   SUCCESS: '放款成功',
      //   FAIL: '放款失败',
      //   REJECT: '已经驳回',
      // },
      // payChannel: {
      //   JHT: '京户通',
      // },
      // personCardType: {
      //   IDCARD: '身份证',
      //   COMPANY_LICENSE: '营业执照',
      // },
      // legalType: {
      //   IDCARD: '身份证',
      //   USCC: '营业执照',
      // },
    },
    access: {
      // 申请类型
      // applyType: {
      //   ADD: '新增授信',
      //   EXPIRED: '重授信(授信过期)',
      //   LEGAL_CHANGE: '重授信（法人变更）',
      //   ID_CARD_EXPIRED: '重授信(身份证到期)',
      //   CERT_EXPIRED: '重授信（营业执照到期) ',
      //   INCREASE: '主动提额',
      // },
      // 当前节点
      // currentNode: {
      //   E_STANDARD_REAL_NAME: '企业实名',
      //   ADDITIONAL_INFO: '补充信息',
      //   SIGN_CONTRACT: '合同签署',
      //   ACCESS_AUDIT: '资质审核',
      //   RISK_AUDIT: '风控审核',
      //   FUND_AUDIT: '资方审核',
      //   FUND_CALLBACK: '资方结果回调',
      //   PRODUCT_OPEN: '产品开通',
      // },
      // state: {
      //   SUCCESS: '审核成功',
      //   FAIL: ' 审核失败',
      //   PROCESS: '审核中',
      // },
      name: {},
    },
    loan: {
      // 放款状态
      // financeState: {
      //   RISK_AUDIT: '审核中',
      //   RISK_FAIL: '审核拒绝',
      //   FUND_AUDIT: '放款中',
      //   FUND_FAIL: '放款失败',
      //   FUND_SUCCESS: '放款成功',
      // },
      // // 申请状态
      // applyState: {
      //   PROCESS: '待审核',
      //   SUCCESS: '融资成功',
      //   FAIL: '融资失败',
      // },
      // userRepayStatus: {
      //   Y: '已结清',
      //   N: '未结清',
      // },
      // status: {
      //   OVERDUE: '已逾期',
      //   SETTLED: '已结清',
      //   UNCLEARED: '未结清',
      // },
      // repayMode: {
      //   INT_AND_CAP_CLEAR: '一次性还本付息',
      //   INT_1_CAP_2: '按月付息，到期还本',
      //   INT_1_CAP_2_FIX: '按月付息，到期还本',
      //   EQUAL_INSTALLMENT: '等本等息',
      //   AVE_CAP_PLUS_INT: '等额本息',
      //   AVE_CAPITAL: '等额本金',
      //   LSBQ: '利随本清',
      // },
      // // 还款计划
      // repayType: {
      //   OVERDUE: '已逾期',
      //   SETTLED: '已结清',
      //   UNCLEARED: '未结清',
      // },
    },
    info: {
      // userType: {
      //   COMPANY: '企业用户',
      //   PERSON: '个人用户',
      // },
      // state: {
      //   OPENING: '开通中',
      //   OPENED: '已开通',
      // },
    },
    accountMg: {
      userRole: {
        LOAN: '融资客户',
        CORE: '核心企业',
        CAPITAL: '资金方',
      },
      purpose: {
        PAY: '放款账户',
        RECEIVE: '收款账户',
        CERTIFY: '实名认证',
      },
      userType: {
        COMPANY: '公司',
        PERSON: '个人',
      },
      certType: {
        USCC: '统一社会信用代码',
        COMPANY_LICENSE: '统一社会信用代码',
      },
      accountType: {
        REAL: '实体户',
        VIRTUAL: '虚户',
      },
      createType: {
        CUSTOMER_ADD: '客户录入',
        ADMIN_ADD: '运营录入',
      },
      enable_state: {
        ENABLE: '有效',
        DISABLE: '无效',
      },
      cus_states: {
        PROCESS: '进行中',
        SUCCESS: '正常',
        FAIL: '失败',
        EXPIRED: '失效',
      },
    },
    financeCustomer: {
      // account: {
      //   REAL: '实体户',
      //   VIRTUAL: '虚户',
      // },
    },
    financeCusDetail: {
      // realState: {
      //   PROCESS: '进行中',
      //   SUCCESS: '正常',
      //   FAIL: '失败',
      //   EXPIRED: '失效',
      // },
      // account: {
      //   REAL: '实体户',
      //   VIRTUAL: '虚户',
      // },
      // enable_state: {
      //   ENABLE: '有效',
      //   DISABLE: '无效',
      // },
    },
    customerFinanceDetail: {
      // operator: {
      //   state: {
      //     ENABLE: '启用',
      //     DISABLE: '停用',
      //   },
      // },
    },
    customerFinance: {
      // userType: {
      //   COMPANY: '企业',
      //   PERSON: '个人',
      // },
      // opDateType: {
      //   SHORT_TERM: '非长期',
      //   LONG_TERM: '长期',
      // },
      // personCertType: {
      //   IDCARD: '身份证',
      //   USCC: '营业执照',
      // },
      // legalCertType: {
      //   IDCARD: '身份证',
      //   USCC: '营业执照',
      // },
      // realChannel: {
      //   EQB: 'E签宝',
      //   SSQ: '上上签',
      // },
      // realState: {
      //   PROCESS: '进行中',
      //   SUCCESS: '正常',
      //   FAIL: '失败',
      //   EXPIRED: '失效',
      // },
      // state: {
      //   OPENING: '开通中',
      //   OPENED: '已开通',
      // },
    },
    //用信管理-还款申请单
    financingRepay: {
      // repayType: {
      //   AUTO_DEDUCT: '自动帐扣',
      //   USER_REPAY: '主动还款',
      //   REDUCE_REPAY: '减免还款',
      //   OFFLINE_REPAY: '线下转账',
      //   GRACE_DEDUCT: '宽限期帐扣',
      //   OVERDUE_DEDUCT: '逾期扣款',
      //   NORMAL_DEDUCT: '到期帐扣',
      // },
      // payWay: {
      //   VIRTUAL: '虚户还款',
      //   OFFLINE_TRANSFER: '线下还款',
      //   ACCOUNT_BALANCE: '账户余额还款',
      //   CASHIER: '收银台',
      // },
      // repayState: {
      //   REPAYING: '还款处理中',
      //   REPAY_SUCCESS: '还款成功',
      //   REPAY_FAIL: '还款失败',
      // },
    },
    account: {
      subjectType: {
        ENTERPRISE: '企业',
        // PERSON: '个人',
      },
      // state: {
      //   PROCESS: '审核中',
      //   SUCCESS: '已生效',
      //   REJECT: '未通过',
      // },
    },
    repay: {
      // payWay: {
      //   // 还款类型
      //   ACCOUNT_BALANCE: '账户余额还款(虚户专用)',
      //   OFFLINE_TRANSFER: '线下转账认领',
      //   CASHIER: '收银台',
      // },
      // // 还款状态
      // repayState: {
      //   REPAYING: '还款中',
      //   REPAY_SUCCESS: '还款成功',
      //   REPAY_FAIL: '还款失败',
      // },
      // // 用户的实名状态
      // state: {
      //   NOT_FOUND: '未实名',
      //   PROCESS: '实名中',
      //   FAIL: '实名失败',
      //   SUCCESS: '实名成功',
      // },
      // // 融资状态
      // LoanStatus: {
      //   PROCESS: '待审核',
      //   SUCCESS: '融资成功',
      //   FAIL: '未通过',
      //   REJECT: '审核已经驳回',
      // },
    },
    // 合同
    contract: {
      // 签约状态
      // state: {
      //   PROCESS: '签约中',
      //   SUCCESS: '成功',
      //   FAIL: '签约失败',
      // },
      // signAutoType: {
      //   AUTO: '自动签署',
      //   MANUAL: '手动签署',
      // },
      // signAutoType1: {
      //   AUTO: '委托签署',
      //   MANUAL: '主动签署',
      // },
      // subjectType: {
      //   LOAN: '融资客户签署',
      //   CORE: '核心企业签署',
      //   GUARANTOR: '担保方签署',
      // },
      // // 场景
      // scene: {
      //   FINANCE: '采购融资',
      //   ACCESS: '准入合同',
      // },
      // bizType: {
      //   ACCESS: '准入',
      //   FINANCE: '融资',
      //   CONFIRMATION: '确权',
      //   LEGAL_GUARANTEE: '法人担保',
      // },
      // signState: {
      //   NORMAL: '正常',
      //   EXPIRED: '过期',
      // },
      // signType: {
      //   LOAN_USER: '借款方',
      //   PLATFORM: '平台方',
      //   GUARANTOR: '担保方',
      // },
    },
    white: {
      //   state: {
      //     USED: '已使用',
      //     NOTINVITE: '未邀请',
      //     HASINVITE: '已邀请',
      //   },
      //   customerType: {
      //     COMPANY: '企业',
      //     PERSON: '个人',
      //   },
      //   certType: {
      //     IDCARD: '身份证',
      //     USCC: '营业执照',
      //   },
      //   sendState: {
      //     INIT: '待发送',
      //     SUCCESS: '发送成功',
      //     FAIl: '发送失败',
      //   },
      //   inviteState: {
      //     VALID: '有效',
      //     USED: '已使用',
      //     EXPIRED: '已过期',
      //     DELETED: '删除',
      //     NOTINVITE: '未邀请',
      //     HASINVITE: '已邀请',
      //   },
      // },
      // batch: {
      //   batchState: {
      //     INIT: '未处理',
      //     PROCESS: '处理中',
      //     COMPLETE: '已完成',
      //   },
      // },
      // department: {
      //   deptType: {
      //     DEPT: '部门',
      //     COMPANY: '分公司',
      //   },
      // },
      // operator: {
      //   operatorIdCardType: {
      //     IDENTITY: '身份证',
      //   },
    },
    //应收账款
    receivable: {
      // financeState: {
      //   FINANCING: '可融资',
      //   NON_FINANCING: '不可融资',
      // },
      // payState: {
      //   UNPAY: '未融资',
      //   PROCESS: '放款中',
      //   PAID: '已融资',
      //   FAIL: '融资失败',
      //   AUDITING: '审核中',
      // },
      // state: {
      //   CREATED: '订单创建',
      //   PART_SETTLE: '部分结算',
      //   CANCEL: '取消',
      //   SETTLE: '已经结算',
      //   EXPIRED: '已经过期',
      // },
      // financeState: {
      //   INIT: '初始',
      //   PROCESS: '待审核',
      //   SUCCESS: '融资成功',
      //   FAIL: '未通过',
      //   REJECT: '审核已经驳回',
      // },
      // SubjectSourceEnum: {
      //   INTERFACE: '接口导入',
      //   MANUAL: '运营人员导入',
      // },
    },
    credit: {
      // applyType: {
      //   ADD: '新增授信',
      //   EXPIRED: '重授信(授信过期) ',
      //   LEGAL_CHANGE: '重授信(法人变更)',
      //   ID_CARD_EXPIRED: '重授信(身份证到期)',
      //   CERT_EXPIRED: '重授信(营业执照到期)',
      //   INCREASE: '主动提额',
      // },
      applyNode: {
        // E_STANDARD_REAL_NAME: '企业实名',
        // SIGN_CONTRACT: '合同签署',
        // ADDITIONAL_INFO: '补充信息',
        // ACCESS_AUDIT: '资质审核',
        // FUND_AUDIT: '资方审核',
        // FUND_CALLBACK: '资方结果回调',
        // PRODUCT_OPEN: '产品开通',
        实名信息保存: '实名信息保存',
        合同信息保存: '合同信息保存',
        风控审核: '风控审核',
        授信结果通知: '授信结果通知',
        结束: '结束',
        风控额度确认: '风控额度确认',
        产品开通: '产品开通',
      },
      nodeState: {
        PROCESS: '审核中',
        SUCCESS: '通过',
        FAIL: '拒绝',
      },
      businessType: {
        YS: '应收融资',
        CG: '采购融资',
        DC: '动产',
        XY: '信用',
        PJ: '票据',
      },
      userRole: {
        LOAN: '融资客户',
        CORE: '核心企业',
        CAPITAL: '资金方',
        GUARANTOR: '担保方',
      },
      role: { LOAN: '融资方', CONFIRM: '确权方' },
      bizState: {
        ENABLE: '正常',
        DISABLE: '停用',
        FROZEN: '风险冻结',
      },
      personCertType: {
        COMPANY: '企业',
        PERSON: '个人',
        COMPANY_LICENSE: '营业执照',
        USCC: '营业执照',
      },
      nodeStateProcess: {
        WAIT_OPERATION: '待操作',
        IN_OPERATION: '操作中',
        DONE: '已完成',
      },
      state: {
        SUCCESS: '成功',
        FAIL: '失败',
        PROCESS: '审核中',
      },
      auditResult: {
        audit_in: '审核中',
        audit_pass: '审核通过',
        audit_reject: '审核拒绝',
        audit_return: '审核驳回',
        audit_error: '审核异常',
        audit_cancel: '审核取消',
      },
      limitType: {
        FIX: '固定',
        TEMP: '临时',
        XE: '限额',
      },
    },
    userManage: {
      loginAccStatus: {
        NORMAL: '正常',
      },
      loginMode: {
        AUTONOMOUS_LOGIN: '自主登录',
      },
    },
    // userManage: {
    //   loginAccStatus: {
    //     NORMAL: '正常',
    //   },
    //   loginMode: {
    //     AUTONOMOUS_LOGIN: '自主登录',
    //   },
    // },
    capital: {
      // realState: {
      //   PROCESS: '待审核',
      //   SUCCESS: '审核成功',
      //   FAIL: '未通过',
      //   EXPIRED: '已经过期',
      // },
      // personCertType: {
      //   IDCARD: '身份证',
      //   USCC: '营业执照',
      // },
    },
    coreEnterprise: {
      // realState: {
      //   PROCESS: '待审核',
      //   SUCCESS: '已认证',
      //   FAIL: '未认证',
      //   EXPIRED: '已经过期',
      // },
      // state: {
      //   ENABLE: '正常',
      //   DISABLE: '失效',
      // },
    },
    enterpriseInfo: {
      certType: {
        USCC: '营业执照',
      },
      legalCertType: {
        IDCARD: '身份证',
      },
      userRole: {
        LOAN: '融资客户',
        CAPITAL: '资金方',
        CORE: '核心企业',
        GUARANTOR: '担保方',
      },
      userType: {
        COMPANY: '企业',
        PERSON: '个体工商户',
      },
      opDateType: {
        SHORT_TERM: '非长期',
        LONG_TERM: '长期',
      },
      userState: {
        DISABLE: '停用',
        ENABLE: '启用',
      },
      realChannel: {
        EQB: '易签宝',
        SSQ: '上上签',
        JD: '京东科技',
      },
      realState: {
        SUCCESS: '已实名',
        FAIL: '认证失败',
        EXPIRED: '实名过期',
      },
      agentRealType: {
        FACTOR4: '银行卡四要素',
        FACE: '人脸',
        OPERATOR: '运营商',
      },
      industries: {
        A: '农、林、牧、渔业',
        B: '采掘业',
        C: '制造业',
        D: '电力、燃气及水的生产和供应业',
        E: '建筑业',
        F: '交通运输、仓储和邮政业',
        G: '信息传输、计算机服务和软件业',
        H: '批发和零售业',
        I: '住宿和餐饮业',
        J: '金融业',
        K: '房地产业',
        L: '租赁和商务服务业',
        M: '科学研究、技术服务业和地质勘察业',
        N: '水利、环境和公共设施管理业',
        O: '居民服务和其他服务业',
        P: '教育',
        Q: '卫生、社会保障和社会福利业',
        R: '文化、体育和娱乐业',
        S: '公共管理和社会组织',
        T: '国际组织',
        Z: '未知',
      },
    },
    potentialList: {
      accessState: {
        ENABLE: '有效',
        DISABLE: '无效',
        SUCCESS: '有效',
        FAIL: '无效',
      },
      createType: {
        MANUAL: '人工导入',
        SYSTEM: '系统录入',
      },
    },
    product: {
      activeRepaymentChannels: {
        OFFLINE_REMITTANCE: '线下汇款还款',
        ONLINE_BANKING: '网银还款',
        ELECTRONIC_ACCOUNT_BALANCE: '电子账户余额',
      },
      activeRepayReversalSequence: {
        REPAYMENT_OF_PRINCIPAL_ONLY: '只还本金',
        INTEREST_PAYMENT_ONLY: '只还息费',
        PRINCIPAL_BEFORE_INTEREST: '先本后息',
        INTEREST_BEFORE_CAPITAL: '先息后本',
      },
      statementRepayChannel: {
        OFFLINE_REMITTANCE: '线下汇款还款',
        ONLINE_BANKING: '网银还款',
        ELECTRONIC_ACCOUNT_BALANCE: '电子账户余额',
      },
      balanceTransferChannel: {
        OFFLINE_REMITTANCE: '线下汇款还款',
        ONLINE_BANKING: '网银还款',
        ELECTRONIC_ACCOUNT_BALANCE: '电子账户余额',
      },
      capitalPath: {
        DIRECT_LOAN: '直接放款',
        VIRTUAL_ACCOUNT_TRANSFER: '虚户中转',
      },
      statementRepayRule: {
        REPAYMENT_TO_ALL_LOANS: '还至所有融资',
        REPAYMENT_TO_WATER_LEVEL: '还至水位线',
      },
      statementRepayReversalSequence: {
        REPAYMENT_OF_PRINCIPAL_ONLY: '只还本金',
        INTEREST_PAYMENT_ONLY: '只还息费',
        PRINCIPAL_BEFORE_INTEREST: '先本后息',
        INTEREST_BEFORE_CAPITAL: '先息后本',
      },
      realNameChannel: {
        EQB: 'E签宝',
        SSQ: '上上签',
        JD: '京东',
      },
      holidayProlong: {
        NORMAL_TERM: '正常计息期',
        GRACE_PERIOD: '宽限期',
        EXTENSION_PERIOD: '展期',
      },
      interestRule: {
        REPAYMENT_ON_THE_DAY_OF_LOAN_2phase: '放款当前还款(2期)',
        REPAYMENT_ON_THE_NEXT_DAY_OF_LOAN: '放款次日计息',
      },
      repayMode: {
        INT_AND_CAP_CLEAR: '一次性还本付息',
        INT_1_CAP_2: '按月付息，到期还本',
        INT_1_CAP_2_FIX: '按月付息，到期还本',
        EQUAL_INSTALLMENT: '等本等息',
        AVE_CAP_PLUS_INT: '等额本息',
        AVE_CAPITAL: '等额本金',
        LSBQ: '利随本清',
        XXHB: '先息后本',
        ARJT: '按日均摊',
        DEJX: '等额减息',
        OTHER: '其他',
      },
      termUnit: {
        DAY: '天',
        MONTH: '月',
        YEAR: '年',
      },
      rateType: {
        FIXED_RATE: '固定利率',
        FLOAT_RATE: '浮动利率',
      },
      productModel: {
        CG: '采购融资',
        YS: '应收融资',
        CGRZ: '采购融资',
        YSRZ: '应收融资',
      },
      guaranteeType: {
        LEGAL_PERSON: '法人担保',
        COMPANY: '公司',
      },
      state: {
        ENABLE: '启用',
        DISABLE: '禁用',
        UNABLE: '未启用',
      },
      loanTimeLimit: {
        CUSTOM_DAYS: '自定义天数',
        ONE_MONTH: '1个月',
        THREE_MONTHS: '3个月',
        SIX_MONTHS: '6个月',
        TWELVE_MONTHS: '12个月',
      },
    },
    task: {
      bizType: {
        RISK_BUYER_ACCESS_MANUAL_AUDIT: '风控授信买方准入审核',
        RISK_LOAN_MANUAL_AUDIT: '风控融资放款审核',
        RISK_SELLER_ACCESS_MANUAL_AUDIT: '风控授信卖方准入审核',
        RISK_LIMIT_OPEN_MANUAL_AUDIT: '风控额度开通审核',
      },
      status: {
        PENDING: '待审核',
        FINISH: '已审核',
      },
    },
    // task: {
    //   bizType: {},
    //   status: {
    //     PENDING: '待审核',
    //     FINISH: '已审核',
    //   },
    // },
  },
}
