export default [
  {
    // 首页，登录后
    path: '/home',
    name: 'home',
    meta: {
      title: 'menu.home',
    },
    component: () => import('../views/home/index.vue'),
  },
  {
    // 无权限
    path: '/403',
    name: '403',
    component: () => import('../views/error/403.vue'),
  },
  {
    path: '/core/operator',
    name: 'core-operator',
    component: () => import('../views/core/Operator.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:operator',
      title: 'menu.coreOperator',
      enum: ['operatorState'],
      keepAlive: true,
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.coreOperator',
        },
      ],
    },
  },
  {
    path: '/core/operator/detail/:ERP_ID',
    name: 'core-operator-detail',
    component: () => import('../views/core/OperatorDetail.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:operator:DETAIL',
      title: 'menu.coreOperator',
      enum: ['operatorState'],
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.coreOperator',
          path: '/core/operator',
        },
        {
          title: '操作员详情',
        },
      ],
    },
  },
  {
    path: '/core/role',
    name: 'core-role',
    component: () => import('../views/core/Role.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:role',
      title: 'menu.coreRole',
      keepAlive: true,
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.coreRole',
        },
      ],
    },
  },
  {
    path: '/core/role/:roleNo',
    name: 'core-role-detail',
    component: () => import('../views/core/RoleDetail.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:role:DETAIL',
      title: 'menu.coreRoleDetail',
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.coreRole',
          path: '/core/role',
        },
        {
          title: 'menu.coreRoleDetail',
        },
      ],
    },
  },
  {
    path: '/core/post',
    name: 'core-post',
    component: () => import('../views/core/Post.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:post',
      title: 'menu.corePost',
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.corePost',
        },
      ],
    },
  },
  {
    path: '/core/department',
    name: 'core-department',
    component: () => import('../views/core/Department.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:dept',
      enum: ['deptType'],
      title: 'menu.coreDepartment',
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.coreDepartment',
        },
      ],
    },
  },
  {
    path: '/subject_matter/receivable',
    name: 'receivable',
    component: () => import('../views/subjectMatter/Receivable.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:receivable',
      enum: ['SUBJECT_STATE_YSRZ', 'SUBJECT_PAY_STATE_YSRZ', 'SUBJECT_SOURCE_YSRZ'],
      title: 'menu.subjectReceivable',
      keepAlive: true,
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectReceivable',
        },
      ],
    },
  },
  {
    path: '/subject_matter/receivable/:subjectNo/:isOperate',
    name: 'subjectReceivable-detail',
    component: () => import('../views/subjectMatter/ReceivableDetail.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:receivable:DETAIL',
      enum: ['SUBJECT_STATE_YSRZ', 'SUBJECT_PAY_STATE_YSRZ', 'SUBJECT_SOURCE_YSRZ'],
      title: 'menu.subjectReceivableDetail',
      keepAlive: true,
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectReceivable',
          path: '/subject_matter/receivable',
        },
        {
          title: 'menu.subjectReceivableDetail',
        },
      ],
    },
  },
  {
    path: '/subject_matter/receivable/history-order/:subjectNo/:isOperate',
    name: 'subjectReceivable-history-detail',
    component: () => import('../views/subjectMatter/ReceivableDetail.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:receivable:DETAIL',
      enum: ['SUBJECT_STATE_YSRZ', 'SUBJECT_PAY_STATE_YSRZ', 'SUBJECT_SOURCE_YSRZ'],
      title: 'menu.subjectReceivableDetailHistory',
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectReceivable',
          path: '/subject_matter/receivable',
        },
        {
          title: 'menu.subjectReceivableDetail',
          path: params => `/subject_matter/receivable/${params.subjectNo}/${params.isOperate}`,
        },
        {
          title: 'menu.subjectReceivableDetailHistory',
        },
      ],
    },
  },
  {
    path: '/subject_matter/purchase',
    name: 'purchase',
    component: () => import('../views/subjectMatter/Purchase.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:purchase',
      enum: ['SUBJECT_STATE_CGRZ', 'SUBJECT_PAY_STATE_CGRZ'],
      title: 'menu.subjectPurchase',
      keepAlive: true,
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectPurchase',
        },
      ],
    },
  },
  {
    path: '/subject_matter/purchase/:subjectNo/:isOperate',
    name: 'purchase-detail',
    component: () => import('../views/subjectMatter/PurchaseDetail.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:purchase:DETAIL',
      enum: ['SUBJECT_STATE_CGRZ', 'SUBJECT_PAY_STATE_CGRZ'],
      title: 'menu.subjectPurchaseDetail',
      keepAlive: true,
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectPurchase',
          path: '/subject_matter/purchase',
        },
        {
          title: 'menu.subjectPurchaseDetail',
        },
      ],
    },
  },
  {
    path: '/subject_matter/purchase/history-order/:subjectNo/:isOperate',
    name: 'purchase-history-detail',
    component: () => import('../views/subjectMatter/PurchaseDetail.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:purchase:DETAIL',
      enum: ['SUBJECT_STATE_CGRZ', 'SUBJECT_PAY_STATE_CGRZ'],
      title: 'menu.subjectPurchaseDetailHistory',
      breads: [
        {
          title: 'menu.subjectMatter',
        },
        {
          title: 'menu.subjectPurchase',
          path: '/subject_matter/purchase',
        },
        {
          title: 'menu.subjectPurchaseDetail',
          path: params => `/subject_matter/purchase/${params.subjectNo}/${params.isOperate}`,
        },
        {
          title: 'menu.subjectPurchaseDetailHistory',
        },
      ],
    },
  },
  {
    path: '/receivable/fancing-scale',
    name: 'receivable-fancing-scale',
    component: () => import('../views/receivable/FancingScale.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:scale',
      title: 'menu.fancingScaleSo',
      keepAlive: true,
      breads: [
        {
          title: 'menu.receivableAccounts',
        },
        {
          title: 'menu.fancingScale',
        },
      ],
    },
  },
  {
    path: '/receivable/fancing-scale/:buyerCustomerId/:coreEnterprisePlatformNo/:productCode/:sellerCustomerId',
    name: 'receivable-fancing-scale-detail',
    component: () => import('../views/receivable/FancingScaleDetail.vue'),
    meta: {
      functionCode: 'erp:subject_matter_manage:scale:DETAIL',
      title: 'menu.fancingScaleSo',
      breads: [
        {
          title: 'menu.receivableFinancing',
        },
        {
          title: 'menu.fancingScaleSo',
        },
      ],
    },
  },
  {
    path: '/credit/apply',
    name: 'credit-apply',
    component: () => import('../views/credit/CreditApply.vue'),
    meta: {
      functionCode: 'erp:credit_manage:access',
      enum: ['productRole', 'userRole', 'productType', 'applyType', 'accessState'],
      keepAlive: true,
      title: '准入管理',
      breads: [
        {
          title: '授信管理',
        },
        {
          title: '准入管理',
          path: '/credit/apply',
        },
      ],
    },
  },
  {
    path: '/credit/apply/detail/:accessNo/:customerNo',
    name: 'credit-apply-detail',
    component: () => import('../views/credit/CreditApplyDetail.vue'),
    meta: {
      functionCode: 'erp:credit_manage:access:DETAIL',
      enum: [
        'auditResult',
        'productType',
        'applyType',
        'accessState',
        'userRole',
        'productRole',
        'certType',
        'limitType',
        'currentNode',
      ],
      title: '准入申请详情',
      breads: [
        {
          title: '授信管理',
        },
        {
          title: '准入管理',
          path: '/credit/apply',
        },
        {
          title: '准入申请详情',
        },
      ],
    },
  },
  {
    path: '/credit/customer-products',
    name: 'customer-products',
    component: () => import('../views/credit/CustomerAndProducts.vue'),
    meta: {
      functionCode: 'erp:credit_manage:userproduct',
      enum: ['productType', 'userRole', 'productRole', 'bizState'],
      title: '客户产品关系',
      breads: [{ title: '授信管理' }, { title: '客户产品关系' }],
    },
  },
  {
    path: '/business_board',
    name: 'business_board',
    meta: {
      functionCode: 'erp:business_board',
      title: '业务看板',
      breads: [],
    },
    component: () => import('../views/businessBoard/index.vue'),
  },
  {
    path: '/login/management',
    name: 'user-manage',
    component: () => import('../views/user-manage/userManage.vue'),
    meta: {
      functionCode: 'erp:login_manage',
      enum: ['loginAccStatus'],
      keepAlive: true,
      title: '用户管理',
      breads: [{ title: '用户管理' }],
    },
  },
  {
    path: '/login/managementDetail/:id',
    name: 'user-manage-detail',
    component: () => import('../views/user-manage/userManageDetail.vue'),
    meta: {
      functionCode: 'erp:login_manage:management:DETAIL',
      enum: ['loginAccStatus', 'loginMode'],
      title: '用户详情',
      breads: [{ title: '用户管理', path: '/login/management' }, { title: '用户详情' }],
    },
  },
  {
    path: '/financing/apply',
    name: 'financing-apply',
    component: () => import('../views/financing/Apply.vue'),
    meta: {
      functionCode: 'erp:financing_management:apply',
      title: 'menu.creditApply',
      keepAlive: true,
      enum: ['financeState', 'financeNode', 'repayMode', 'nodeState', 'financeDurationType'],
      breads: [
        {
          title: 'menu.creditManagement',
        },
        {
          title: 'menu.creditApply',
        },
      ],
    },
  },
  {
    path: '/financing/apply/:customerNo/:financeNo',
    name: 'financing-apply-detail',
    component: () => import('../views/financing/ApplyDetail.vue'),
    meta: {
      functionCode: 'erp:financing_management:apply:DETAIL',
      title: 'menu.creditApplyDetail',
      enum: [
        'auditResult',
        'financeState',
        'repayMode',
        'financeState',
        'financeNode',
        'nodeState',
        'certType',
        'financeDurationType',
      ],
      breads: [
        {
          title: 'menu.creditManagement',
        },
        {
          title: 'menu.creditApply',
          path: '/financing/apply',
        },
        {
          title: 'menu.creditApplyDetail',
        },
      ],
    },
  },
  {
    path: '/financing/repay-apply',
    name: 'financing-repay',
    component: () => import('../views/financing/RepayApply.vue'),
    meta: {
      functionCode: 'erp:credit_management:financing',
      title: 'menu.creditRepay',
      keepAlive: true,
      enum: ['repayState', 'nodeState', 'repayType', 'payWay', 'repayNode'],
      breads: [
        {
          title: 'menu.creditManagement',
        },
        {
          title: 'menu.creditRepay',
        },
      ],
    },
  },
  {
    path: '/financing/repay-apply/detail/:repayNo/:customerNo',
    name: 'financing-repay-detail',
    component: () => import('../views/financing/RepayApplyDetail.vue'),
    meta: {
      functionCode: 'erp:credit_management:financing:DETAIL',
      title: '还款详情',
      enum: ['certType', 'repayState', 'nodeState', 'repayType', 'payWay', 'repayNode'],
      breads: [
        {
          title: '用信管理',
        },
        {
          title: '还款申请单',
          path: '/financing/repay-apply',
        },
        { title: '还款详情' },
      ],
    },
  },
  {
    path: '/contract/management',
    name: 'contract-management',
    component: () => import('../views/contract/ContractManagement.vue'),
    meta: {
      functionCode: 'erp:contract_manage:management',
      enum: ['bizType', 'signState'],
      keepAlive: true,
      title: '合同签署记录',
      breads: [{ title: '合同管理' }, { title: '合同签署记录' }],
    },
  },
  {
    path: '/contract/management/:contractFileNo',
    name: 'contract-detail',
    component: () => import('../views/contract/ContractManagementDetail.vue'),
    meta: {
      functionCode: 'erp:contract_manage:management:DEATIL',
      enum: ['bizType', 'signState', 'signAutoType', 'contractState'],
      title: '合同签署详情',
      breads: [
        { title: '合同管理' },
        { title: '合同签署记录', path: '/contract/management' },
        { title: '合同签署详情' },
      ],
    },
  },
  {
    path: '/loan/project',
    name: 'loan-project',
    component: () => import('../views/loan/Project.vue'),
    meta: {
      functionCode: 'erp:loan_manage:project',
      enum: ['monitorType'],
      title: 'menu.loanProject',
      breads: [
        {
          title: 'menu.loanManage',
        },
        {
          title: 'menu.loanProject',
        },
      ],
    },
  },
  {
    path: '/loan/danger',
    name: 'loan-danger',
    component: () => import('../views/loan/Danger.vue'),
    meta: {
      functionCode: 'erp:loan_manage:danger',
      enum: ['level'],
      title: 'menu.loanDanger',
      breads: [
        {
          title: 'menu.loanManage',
        },
        {
          title: 'menu.loanDanger',
        },
      ],
    },
  },
  {
    path: '/account/core',
    name: 'account-core',
    component: () => import('../views/account/Core.vue'),
    meta: {
      functionCode: 'erp:account_manage:core',
      enum: ['customerType', 'companyCertType', 'createType', 'accountType', 'accountEnableState'],
      keepAlive: true,
      title: 'menu.accountCompanyInfo',
      breads: [
        {
          title: 'menu.account',
        },
        {
          title: 'menu.accountCompanyInfo',
        },
      ],
    },
  },
  {
    path: '/account/core/:userNo/:receiptNo',
    name: 'account-core-detail',
    component: () => import('../views/account/CoreDetail.vue'),
    meta: {
      functionCode: 'erp:account_manage:core:DETAIL',
      enum: [
        'customerType',
        'companyCertType',
        'createType',
        'accountType',
        'accountEnableState',
        'userRole',
        'purpose',
      ],
      title: 'menu.accountDetail',
      breads: [
        {
          title: 'menu.account',
        },
        {
          title: 'menu.accountCompanyInfo',
          path: '/account/core',
        },
        {
          title: 'menu.accountDetail',
        },
      ],
    },
  },
  {
    path: '/potential/potential-list',
    name: 'potential-list',
    component: () => import('../views/potentialCustomer/PotentialList.vue'),
    meta: {
      functionCode: 'erp:potential_customer_manage:management',
      enum: ['customerType', 'potentialState', 'createType', 'timeDimension'],
      title: 'menu.potentialList',
      keepAlive: true,
      breads: [
        {
          title: 'menu.potentialMgmt',
        },
        {
          title: 'menu.potentialList',
        },
      ],
    },
  },
  {
    path: '/potential/potential-list/:whiteListNo/:isOperate',
    name: 'potential-list-detail',
    component: () => import('../views/potentialCustomer/PotentialListDetail.vue'),
    meta: {
      functionCode: 'erp:potential_customer_manage:management:DETAIL',
      enum: ['customerType', 'potentialState', 'createType', 'timeDimension'],
      title: 'menu.potentialListDetail',
      keepAlive: true,
      breads: [
        {
          title: 'menu.potentialMgmt',
        },
        {
          title: 'menu.potentialList',
          path: '/potential/potential-list',
        },
        {
          title: 'menu.potentialListDetail',
        },
      ],
    },
  },
  {
    path: '/potential/potential-update-list/:whiteListNo/:isOperate',
    name: 'potential-update-list-detail',
    component: () => import('../views/potentialCustomer/PotentialListDetail.vue'),
    meta: {
      functionCode: 'erp:potential_customer_manage:management:DETAIL',
      enum: ['customerType', 'potentialState', 'createType', 'timeDimension'],
      title: 'menu.potentialUpdateListDetail',
      breads: [
        {
          title: 'menu.potentialMgmt',
        },
        {
          title: 'menu.potentialList',
          path: '/potential/potential-list',
        },
        {
          title: 'menu.potentialListDetail',
          path: params => {
            return `/potential/potential-list/${params.whiteListNo}/${params.isOperate}`
          },
        },
        {
          title: 'menu.potentialUpdateListDetail',
        },
      ],
    },
  },
  {
    path: '/enterprise/enterprise-info',
    name: 'enterprise-info',
    component: () => import('../views/enterprise/EnterpriseInfo.vue'),
    meta: {
      functionCode: 'erp:enterprise_mgmt:enterpriseInfo',
      enum: ['industries', 'companyCertType', 'userRole', 'contactType', 'customerType', 'certType', 'personCertType'],
      title: 'menu.enterpriseInfo',
      keepAlive: true,
      breads: [
        {
          title: 'menu.enterpriseMgmt',
        },
        {
          title: 'menu.enterpriseInfo',
        },
      ],
    },
  },
  {
    path: '/enterprise/enterprise-info/:userNo',
    name: 'enterprise-info-detail',
    component: () => import('../views/enterprise/EnterpriseInfoDetail.vue'),
    meta: {
      functionCode: 'erp:enterprise_mgmt:enterpriseInfo:DETAIL',
      enum: [
        'industries',
        'companyCertType',
        'userRole',
        'contactType',
        'customerType',
        'userState',
        'productRole',
        'certType',
      ],
      title: 'menu.enterpriseInfoDetail',
      breads: [
        {
          title: 'menu.enterpriseMgmt',
        },
        {
          title: 'menu.enterpriseInfo',
          path: '/enterprise/enterprise-info',
        },
        {
          title: 'menu.enterpriseInfoDetail',
        },
      ],
    },
  },
  {
    path: '/enterprise/enterprise-real-info',
    name: 'enterprise-real-info',
    component: () => import('../views/enterprise/EnterpriseRealInfo.vue'),
    meta: {
      functionCode: 'erp:enterprise_mgmt:enterpriseRealInfo',
      enum: ['customerType', 'realState', 'realChannel', 'companyCertType'],
      title: 'menu.enterpriseRealInfo',
      keepAlive: true,
      breads: [
        {
          title: 'menu.enterpriseMgmt',
        },
        {
          title: 'menu.enterpriseRealInfo',
        },
      ],
    },
  },
  {
    path: '/enterprise/enterprise-real-info/:userNo/:id/:isOperate',
    name: 'enterprise-real-info-detail',
    component: () => import('../views/enterprise/EnterpriseRealInfoDetail.vue'),
    meta: {
      functionCode: 'erp:enterprise_mgmt:enterpriseRealInfo:DETAIL',
      enum: [
        'agentRealType',
        'customerType',
        'realState',
        'realChannel',
        'companyCertType',
        'industries',
        'certType',
        'userState',
      ],
      title: 'menu.enterpriseRealInfoDetail',
      keepAlive: true,
      breads: [
        {
          title: 'menu.enterpriseMgmt',
        },
        {
          title: 'menu.enterpriseRealInfo',
          path: '/enterprise/enterprise-real-info',
        },
        {
          title: 'menu.enterpriseRealInfoDetail',
        },
      ],
    },
  },
  {
    path: '/enterprise/enterprise-history-real-info/:userNo/:id/:isOperate',
    name: 'enterprise-history-real-info-detail',
    component: () => import('../views/enterprise/EnterpriseRealInfoDetail.vue'),
    meta: {
      functionCode: 'erp:enterprise_mgmt:enterpriseRealInfo:DETAIL',
      enum: [
        'agentRealType',
        'customerType',
        'realState',
        'realChannel',
        'companyCertType',
        'industries',
        'certType',
        'userState',
      ],
      title: 'menu.enterpriseHistoryRealInfoDetail',
      breads: [
        {
          title: 'menu.enterpriseMgmt',
        },
        {
          title: 'menu.enterpriseRealInfo',
          path: '/enterprise/enterprise-real-info',
        },
        {
          title: 'menu.enterpriseRealInfoDetail',
          path: params => {
            return `/enterprise/enterprise-real-info/${params.userNo}/${params.id}/${params.isOperate}`
          },
        },
        {
          title: 'menu.enterpriseHistoryRealInfoDetail',
        },
      ],
    },
  },
  {
    path: '/white/white',
    name: 'white-white',
    component: () => import('../views/white/White.vue'),
    meta: {
      functionCode: 'erp:white_list_manage:productwhitelist',
      enum: ['inviteState', 'customerType', 'certType'],
      keepAlive: true,
      title: 'menu.whiteWhite',
      breads: [
        {
          title: 'menu.white',
        },
        {
          title: 'menu.whiteWhite',
        },
      ],
    },
  },
  {
    path: '/white/white/:whiteNo',
    name: 'white-white-detail',
    component: () => import('../views/white/WhiteDetail.vue'),
    meta: {
      functionCode: 'erp:white_list_manage:productwhitelist:DETAIL',
      enum: ['inviteState', 'customerType', 'certType', 'sendState'],
      title: 'menu.whiteWhiteDetail',
      breads: [
        {
          title: 'menu.white',
        },
        {
          title: 'menu.whiteWhite',
          path: '/white/white',
        },
        {
          title: 'menu.whiteWhiteDetail',
        },
      ],
    },
  },
  {
    path: '/white/batch',
    name: 'white-batch',
    component: () => import('../views/white/Batch.vue'),
    meta: {
      functionCode: 'erp:white_list_manage:batchinvite',
      enum: ['batchState'],
      keepAlive: true,
      title: 'menu.whiteBatch',
      breads: [
        {
          title: 'menu.white',
        },
        {
          title: 'menu.whiteBatch',
        },
      ],
    },
  },
  {
    path: '/white/batch/:inviteBatchNo',
    name: 'white-batch-detail',
    component: () => import('../views/white/BatchDetail.vue'),
    meta: {
      functionCode: 'erp:white_list_manage:batchinvite:DETAIL',
      enum: ['batchState', 'customerType', 'certType', 'sendState'],
      title: 'menu.whiteBatchDetail',
      breads: [
        {
          title: 'menu.white',
        },
        {
          title: 'menu.whiteBatch',
          path: '/white/batch',
        },
        {
          title: 'menu.whiteBatchDetail',
        },
      ],
    },
  },
  {
    path: '/white/batch-add',
    name: 'white-batch-add',
    component: () => import('../views/white/BatchAdd/index.vue'),
    meta: {
      functionCode: 'erp:white_list_manage:batchinvite:ADD',
      title: 'menu.whiteBatchAdd',
      breads: [
        {
          title: 'menu.white',
        },
        {
          title: 'menu.whiteBatch',
          path: '/white/batch',
        },
        {
          title: 'menu.whiteBatchAdd',
        },
      ],
    },
  },
  {
    path: '/product/product',
    name: 'product-product',
    component: () => import('../views/product/Product.vue'),
    meta: {
      functionCode: 'erp:product_manage:management',
      enum: ['productType', 'productState'],
      keepAlive: true,
      title: 'menu.productProduct',
      breads: [
        {
          title: 'menu.product',
        },
        {
          title: 'menu.productProduct',
        },
      ],
    },
  },
  {
    path: '/product/product/:productCode',
    name: 'product-product-detail',
    component: () => import('../views/product/ProductDetail.vue'),
    meta: {
      functionCode: 'erp:product_manage:management:DETAIL',
      enum: [
        'productType',
        'productState',
        'realChannel',
        'rateType',
        'realChannel',
        'repayMode',
        'loanStage',
        'interestRule',
        'repayChannel',
        'repayReversalSequence',
        'statementRepayRule',
        'repayReversalSequence',
        'financeDurationType',
        'loanTimeLimit',
        'capitalPath',
        'settleMode',
        'billPattern',
        'tradeType',
        'Issued',
        'sell',
        'distribution_node',
        'distribution_type',
      ],
      title: 'menu.productProductDetail',
      breads: [
        {
          title: 'menu.product',
        },
        {
          title: 'menu.productProduct',
          path: '/product/product',
        },
        {
          title: 'menu.productProductDetail',
        },
      ],
    },
  },
  {
    path: '/product/product/edit/:productCode',
    name: 'product-edit',
    component: () => import('../views/product/ProductEdit.vue'),
    meta: {
      functionCode: 'erp:product_manage:management:UPDATE',
      enum: [
        'realChannel',
        'rateType',
        'productType',
        'loanStage',
        'interestRule',
        'repayReversalSequence',
        'repayChannel',
        'capitalPath',
        'loanTimeLimit',
        'settleMode',
        'billPattern',
        'tradeType',
        'Issued',
        'sell',
        'distribution_node',
        'distribution_type',
      ],
      title: 'menu.productProductEdit',
      breads: [
        {
          title: 'menu.product',
        },
        {
          title: 'menu.productProduct',
          path: '/product/product',
        },
        {
          title: 'menu.productProductEdit',
        },
      ],
    },
  },
  {
    path: '/product/product/add/:productCode',
    name: 'product-add',
    component: () => import('../views/product/ProductEdit.vue'),
    meta: {
      functionCode: 'erp:product_manage:management:ADD',
      enum: ['realChannel'],
      title: 'menu.productProductAdd',
      breads: [
        {
          title: 'menu.product',
        },
        {
          title: 'menu.productProduct',
          path: '/product/product',
        },
        {
          title: 'menu.productProductAdd',
        },
      ],
    },
  },
  {
    path: '/book/loan',
    name: 'book-loan',
    component: () => import('../views/book/Loan.vue'),
    meta: {
      functionCode: 'erp:book_manage:loan',
      keepAlive: true,
      enum: ['repayModeLedger', 'clearStateLedger', 'writeoffState', 'loanStageLedger'],
      title: 'menu.bookLoan',
      breads: [
        {
          title: 'menu.book',
          path: '/book/loan',
        },
        {
          title: 'menu.bookLoan',
        },
      ],
    },
  },
  {
    path: '/book/loan/:loanNo',
    name: 'book-loan-detail',
    component: () => import('../views/book/LoanDetail.vue'),
    meta: {
      functionCode: 'erp:book_manage:loan:DETAIL',
      enum: ['repayModeLedger', 'clearStateLedger', 'writeoffState', 'loanStageLedger', 'repayDimension'],
      title: 'menu.bookLoanDetail',
      breads: [
        {
          title: 'menu.book',
          path: '/',
        },
        {
          title: 'menu.bookLoan',
          path: '/book/loan',
        },
        {
          title: 'menu.bookLoanDetail',
        },
      ],
    },
  },
  {
    path: '/book/repay',
    name: 'book-repay',
    component: () => import('../views/book/Repay.vue'),
    meta: {
      functionCode: 'erp:book_manage:repay',
      keepAlive: true,
      title: 'menu.bookRepay',
      enum: ['repayDimension'],
      breads: [
        {
          title: 'menu.book',
          path: '/',
        },
        {
          title: 'menu.bookRepay',
        },
      ],
    },
  },
  {
    path: '/book/repay/:repayNo',
    name: 'book-repay-detail',
    component: () => import('../views/book/RepayDetail.vue'),
    meta: {
      functionCode: 'erp:book_manage:repay:DEATIL',
      title: 'menu.bookRepayDetail',
      enum: ['repayDimension'],
      breads: [
        {
          title: 'menu.book',
          path: '/',
        },
        {
          title: 'menu.bookRepay',
          path: '/book/repay',
        },
        {
          title: 'menu.bookRepayDetail',
        },
      ],
    },
  },
  {
    path: '/book/plan',
    name: 'book-plan',
    component: () => import('../views/book/Plan.vue'),
    meta: {
      functionCode: 'erp:book_manage:plan',
      title: 'menu.bookPlan',
      enum: ['repayModeLedger', 'clearStateLedger', 'loanStageLedger'],
      breads: [
        {
          title: 'menu.book',
          path: '/',
        },
        {
          title: 'menu.bookPlan',
        },
      ],
    },
  },
  {
    path: '/task/management',
    name: 'task-management',
    component: () => import('../views/task/Management.vue'),
    meta: {
      functionCode: 'erp:task_manage',
      keepAlive: true,
      enum: ['PROCEE_TASK_BIZ_TYPE', 'productState', 'taskStatus', 'fileType'],
      title: 'menu.taskManagement',
      breads: [
        {
          title: 'menu.taskManagement',
        },
      ],
    },
  },
  {
    path: '/task/management/:taskNo',
    name: 'task-management-detail',
    component: () => import('../views/task/ManagementDetail.vue'),
    meta: {
      functionCode: 'erp:task_manage:management:DETAIL',
      enum: ['fileType'],
      title: 'menu.taskManagementDetail',
      breads: [
        {
          title: 'menu.taskManagement',
        },
        {
          title: 'menu.taskManagementDetail',
        },
      ],
    },
  },
  {
    path: '/task/management/execute/:taskNo',
    name: 'task-management-execute',
    component: () => import('../views/task/ManagementExecute.vue'),
    meta: {
      functionCode: 'erp:task_manage:management:EXECUTE',
      title: 'menu.taskManagementExecute',
      enum: ['fileType'],
      breads: [
        {
          title: 'menu.taskManagementExecute',
        },
        {
          title: 'menu.taskManagementExecute',
        },
      ],
    },
  },
  {
    path: '/treasury/transfer',
    name: 'treasury-transfer',
    component: () => import('../views/treasury/Transfer.vue'),
    meta: {
      functionCode: 'erp:treasury_services:transfer',
      keepAlive: true,
      enum: ['fundTradeTransItem', 'fundTradeTransState', 'fundTradeTimeOut'],
      title: 'menu.treasuryTransfer',
      breads: [
        {
          title: 'menu.treasury',
          path: '/',
        },
        {
          title: 'menu.treasuryTransfer',
        },
      ],
    },
  },
  {
    path: '/treasury/transfer/:transNo/:isOffline/:businessNo/:transItem',
    name: 'treasury-transfer',
    component: () => import('../views/treasury/TransferDetail.vue'),
    meta: {
      functionCode: 'erp:treasury_services:transfer:DETAIL',
      enum: ['fundTradeTransItem', 'fundTradeTransState', 'fundTradeTimeOut'],
      title: 'menu.treasuryTransferDetail',
      breads: [
        {
          title: 'menu.treasury',
          path: '/',
        },
        {
          title: 'menu.treasuryTransfer',
          path: '/treasury/transfer',
        },
        {
          title: 'menu.treasuryTransferDetail',
        },
      ],
    },
  },
  {
    path: '/treasury/passageway',
    name: 'treasury-passageway',
    component: () => import('../views/treasury/Passageway.vue'),
    meta: {
      functionCode: 'erp:treasury_services:passageway',
      keepAlive: true,
      title: 'menu.treasuryThought',
      breads: [
        {
          title: 'menu.treasury',
          path: '/',
        },
        {
          title: 'menu.treasuryThought',
        },
      ],
    },
  },
  {
    path: '/treasury/passageway/:channelCode',
    name: 'treasury-passageway',
    component: () => import('../views/treasury/PassagewayDetail.vue'),
    meta: {
      functionCode: 'erp:treasury_services:passageway:DETAIL',
      title: 'menu.treasuryThoughtDetail',
      breads: [
        {
          title: 'menu.treasury',
          path: '/',
        },
        {
          title: 'menu.treasuryThought',
          path: '/treasury/passageway',
        },
        {
          title: 'menu.treasuryThoughtDetail',
        },
      ],
    },
  },
  {
    path: '/core/holidayManagement',
    name: 'core-holiday-management',
    component: () => import('../views/core/HolidayManagement.vue'),
    meta: {
      functionCode: 'erp:enterprise_manage:holidays',
      title: 'menu.holidayManagement',
      breads: [
        {
          title: 'menu.core',
        },
        {
          title: 'menu.holidayManagement',
        },
      ],
    },
  },
  {
    // 公告
    path: '/notice/notice',
    name: 'notice-notice',
    meta: {
      functionCode: 'erp:enterprise_manage:notice',
      title: 'menu.notice',
      enum: ['noticeReleaseStatus', 'noticeReleaseColumn', 'noticeIsTop'],
      breads: [{ title: 'menu.notice' }, { title: 'menu.noticeNotice' }],
    },
    component: () => import('../views/notice/NoticeList.vue'),
  },
  {
    // 公告
    path: '/notice/notice/:noticeNo',
    name: 'notice-notice-detail',
    meta: {
      functionCode: 'erp:enterprise_manage:notice:INFO',
      title: 'menu.notice',
      enum: ['noticeReleaseStatus', 'noticeReleaseColumn', 'noticeIsTop'],
      breads: [{ title: 'menu.notice' }, { title: 'menu.noticeNotice' }, { title: 'menu.noticeNoticeDetail' }],
    },
    component: () => import('../views/notice/NoticeDetail.vue'),
  },
  {
    path: '/credit-flow/order-line',
    name: 'order-line',
    component: () => import('../views/creditFlow/OrderLine.vue'),
    meta: {
      functionCode: 'erp:credit_flow_manage:orderLine',
      enum: ['QuotaState', 'QuotaShared', 'QuotaUse'],
      title: 'menu.orderLine',
      keepAlive: true,
      breads: [
        {
          title: 'menu.creditFlow',
        },
        {
          title: 'menu.orderLine',
        },
      ],
    },
  },
  {
    path: '/credit-flow/order-line/:quotaNo',
    name: 'order-line-detail',
    component: () => import('../views/creditFlow/OrderLineDetail.vue'),
    meta: {
      functionCode: 'erp:credit_flow_manage:orderLine:DETAIL',
      enum: ['QuotaState', 'QuotaType', 'QuotaShared', 'QuotaUse', 'QuotaCreateType'],
      title: 'menu.orderLineDetail',
      breads: [
        {
          title: 'menu.creditFlow',
        },
        {
          title: 'menu.orderLine',
          path: '/credit-flow/order-line',
        },
        {
          title: 'menu.orderLineDetail',
        },
      ],
    },
  },
  // 风险名单
  {
    path: '/potential/risk',
    name: 'potential-risk',
    component: () => import('../views/potentialCustomer/RiskList.vue'),
    meta: {
      functionCode: 'erp:risk_list:management',
      enum: ['riskListState', 'customerType'],
      title: 'menu.riskList',
      breads: [
        {
          title: 'menu.riskList',
        },
        {
          title: 'menu.riskList',
        },
      ],
    },
  },
  //中信账户管理
  {
    path: '/account/administration',
    name: 'ZX-Administration',
    component: () => import('../views/account/zxAdministration.vue'),
    meta: {
      title: '中信账户管理',
      functionCode: 'erp:account:administration',
      enum: ['createType', 'accountState'],
      breads: [
        {
          title: '中信账户管理',
        },
      ],
    },
  },
  {
    path: '/account/administration/:receiptNo/:customerNo',
    name: 'ZX-Administration-Detail',
    component: () => import('../views/account/zxAdministrationDetail.vue'),
    meta: {
      functionCode: 'erp:account:administration:DETAIL',
      title: '账户详情',
      enum: ['accountState'],
      breads: [
        {
          title: '中信账户管理',
          path: '/account/administration',
        },
        {
          title: '中信账户管理详情',
        },
      ],
    },
  },
  {
    path: '/account/administration/detailed/:receiptNo/:customerNo',
    name: 'ZX-Administration-Detailed',
    component: () => import('../views/account/zxAdminDetailed.vue'),
    meta: {
      functionCode: 'erp:account:administration:DETAIL',
      title: '账户明细',
      enum: ['tradeType'],
      breads: [
        {
          title: '中信账户管理',
          path: '/account/administration',
        },
        {
          title: '中信账户明细',
        },
      ],
    },
  },
  {
    path: '/potential/risk/:companyName',
    name: 'potential-risk-detail',
    component: () => import('../views/potentialCustomer/RiskListDetail.vue'),
    meta: {
      functionCode: 'erp:risk_list:management',
      enum: ['riskListState', 'customerType'],
      title: 'menu.riskListDeatil',
      breads: [
        {
          title: 'menu.riskListDeatil',
        },
        {
          title: 'menu.riskListDeatil',
        },
      ],
    },
  },
]
