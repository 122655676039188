export default [
  /* 宜发展 */
  {
    /**
     * 查询权限
     */
    name: 'queryRouteYFZ',
    method: 'post',
    url: '',
  },
  {
    /**
     * 宜发展登录
     *
     */
    name: 'yfzLogin',
    method: 'post',
    url: '/test',
  },
  {
    /**
     * 宜发展权限
     *
     */
    name: 'jnosAuthUserView',
    method: 'post',
    url: '//jnos-api-inner.crxn.cn/jnos.auth.user.view',
  },
  {
    /**
     * 宜发展权限
     *
     */
    name: 'queryUserId',
    method: 'post',
    url: '//jnos-api-inner.crxn.cn/jnos.account.user.info.query',
  },
  {
    /**
     * 潜客编辑通知接口
     *
     */
    name: 'whitelistNoticeYFZ',
    method: 'post',
    url: '/external/yfz/access/whitelist/notice',
  },
  {
    /**
     * 潜客新增通知接口
     *
     */
    name: 'addWhitelistNoticeYFZ',
    method: 'post',
    url: '/external/yfz/access/add/whitelist/notice',
  },
]
