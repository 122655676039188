export default [
  {
    /**
     * 开单额度列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCreditquotaList',
    method: 'post',
    url: '/admin/creditquota/pageList',
  },
  {
    /**
     * 开单额度详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCreditquotaDetail',
    method: 'post',
    url: '/admin/creditquota/detail',
  },
]
