<script lang="jsx">
import Table from '@/components/ui/Table.vue'
import mixins from '@/utils/mixins'
import { download } from '@/utils'
import empty from '../../../assets/images/empty.png'
import { bus } from '@/utils/index.js'
export default {
  name: 'base-list',
  components: {
    JdbTable: Table,
  },
  mixins: [mixins],
  data() {
    return {
      searchForm: {},
      searchComponent: null,
      rowKey: 'id',
      columns:[],
      title: null,
      exportUrl: null,
      exportFileName: null,
      editData: null,
      editTitle: null,
      edit: false,
      editSize: undefined,
      editMinWidth: undefined,
      dictData:{},
      isOperate:'',
      empty
    }
  },
  provide() {
    return {
      exportUrl: this.exportUrl,
      permission: this.permission
    }
  },
  mounted() {
    bus.$off('handleExport')
    bus.$on('handleExport', this.handleExport)
  },
  methods: {
    async onQuery(fromData) {
      this.searchForm = { ...fromData }
      this.handleQuery()
    },
    handleExport(){
      if(this.exportParams){
        this.searchForm = {data:{...this.searchForm}}
      }
      download(this.convertUrl(this.exportUrl), this.searchForm, this.exportFileName)
    },
    handleClose(query){
      console.log(query)
      this.edit = false
      this.editComponent = null
      if(query){
        this.changePage(this.pagination.pageNum)
        this.isOperate='isOperate'
      }
    },
    getExtend() {},
    getDictData() {}
  },
  render() {
    let SearchComponent = this.searchComponent
    let EditComponent = this.editComponent
    let extendVnode = this.getExtend()
    if (extendVnode) {
      if (extendVnode.children) {
        extendVnode.children.forEach(m => {
          m.componentOptions.propsData.size = 'medium'
        })
      } else {
        extendVnode.componentOptions && (extendVnode.componentOptions.propsData.size = 'medium')
      }
    }

    return (
      <div class="base-list">
        <div class="base mt-0px">
          <div class="bg-white search">
            {extendVnode ? <div class="pt-24px pl-24px -mb-4px">{extendVnode}</div> : null}
            <SearchComponent ref="searchComponentInstance" on-dictData={this.getDictData} on-query={this.onQuery.bind(this)}></SearchComponent>
          </div>
          <div class="list mt-0px">
            {/* <p class="flex justify-end leading-30px border">
              {this.exportUrl ? <fin-button class="mb-16px" v-permission={this.permission} on-click={this.handleExport.bind(this)}>{this.translate('common.export')}</fin-button> : null}
            </p> */}
            <jdb-table
              row-key={this.rowKey}
              columns={this.columns}
              list={this.dataList}
              loading={this.loading}
              pagination={this.pagination}
              on-page-change={this.changePage.bind(this)}
              on-size-change={this.changePageSize.bind(this)}
              emptyText={'暂无数据'}
            >
            </jdb-table>
          </div>
        </div>
        <fin-drawer
          custom-class="min-width"
          title={this.editTitle}
          visible={this.edit}
          size={this.editSize}
          show-close={true}
          wrapper-closable={false}
          {...{on:{'update:visible': ()=>{ this.handleClose()}}}}
          direction="rtl"
          >
          <span slot="title" class="text-m font-bold">{this.editTitle}</span>
          <EditComponent origin={this.editData} dictData={this.dictData} on-close={this.handleClose.bind(this)}></EditComponent>
        </fin-drawer>
      </div>
    )
  },
  beforeRouteEnter(to, from, next){
    next(vm=>{
      if(from.path !== vm.comparePath) {
        vm.$refs.searchComponentInstance.resetForm()
        vm.$refs.searchComponentInstance.handleQuery()
      } else {
        vm.handleReQuery()
      }
    })
  }
}
</script>
<style lang="less">
.base {
  background: #fff;
  .border {
    border-top: 1px dashed #e5e5e5;
  }
}
.jdb-image-viewer__wrapper {
  z-index: 3000 !important;
}
</style>
