export default [
  // 功能描述: 应收账款列表
  {
    name: 'queryReceivablePageList',
    method: 'post',
    url: '/admin/receivable/management/pageList',
  },
  // 功能描述: 应收账款详情
  {
    name: 'queryReceivableDetail',
    method: 'post',
    url: '/admin/receivable/management/queryDetailBySubjectNo',
  },
  // 功能描述: 应收账款信息更新
  {
    name: 'updateReceivableDetail',
    method: 'post',
    url: '/admin/receivable/management/updateBySubjectNo',
  },
  // 功能描述: 应收账款单作废
  {
    name: 'updateReceivableCancel',
    method: 'post',
    url: '/admin/receivable/management/cancelBySubjectNo',
  },
  // 功能描述: 新增应收账款
  {
    name: 'addReceivable',
    method: 'post',
    url: '/admin/receivable/management/save',
  },
  // 功能描述  批量新增
  {
    name: 'allAddReceivable',
    method: 'post',
    url: '/admin/receivable/saveall',
  },

  //历史订单记录
  {
    name: 'historyOrderLog',
    method: 'post',
    url: '/admin/log/pagelist',
  },
  // 查询operatorId
  {
    name: 'getloginoperator',
    method: 'post',
    url: '/admin/operator/getloginoperator',
  },
  {
    name: 'queryPurchasePageList',
    method: 'post',
    url: '/admin/purchase/pageList',
  },
  {
    name: 'queryPurchaseDetail',
    method: 'post',
    url: '/admin/purchase/detail',
  },

  {
    name: 'queryListPageFinanceRatio',
    method: 'post',
    url: '/risk/api/export/manage/listPageFinanceRatio',
  },
  {
    name: 'queryDetailFinanceRatio',
    method: 'post',
    url: '/risk/api/export/manage/detailFinanceRatio',
  },
  {
    name: 'queryProductCfg',
    method: 'post',
    url: '/admin/product/productConfig/getProductCfg',
  },
  {
    name: 'queryPageFinanceRatioHistory',
    method: 'post',
    url: '/risk/api/export/manage/listPageFinanceRatioHistory',
  },
  {
    name: 'queryModifyFinanceRatio',
    method: 'post',
    url: '/risk/api/export/manage/modifyFinanceRatio',
  },
]
