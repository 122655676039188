export default [
  /* 功能描述: 授信申请列表
    http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62902b6f1627a00967238356
  */
  {
    name: 'queryCreditApplyPageList',
    method: 'post',
    url: '/admin/access/pagelist',
  },
  /**  功能描述: 授信申请详情
   * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=629070461627a00967238363
   */
  {
    name: 'getCreditApplyDetail',
    method: 'post',
    url: '/admin/access/detail',
  },
  //授信管理-准入管理详情-客户信息查询
  {
    name: 'getCreditCustomerDetail',
    method: 'post',
    url: '/admin/companyManagement/queryCompany',
  },
  //功能描述：授信节点查询
  {
    name: 'queryAccessProcess',
    method: 'post',
    url: '/admin/access/queryAccessProcess',
  },
  //功能描述：用户信息查询
  {
    name: 'queryUserDetail',
    method: 'post',
    url: '/admin/user/detail',
  },
  /**客户与产品关系
   * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=629096091627a00967238372
   */
  {
    name: 'queryCustomerProduct',
    method: 'post',
    url: '/admin/userProductAdmin/pagelist',
  },
  //查询资金方列表
  {
    name: 'getFundNameList',
    method: 'post',
    url: '/admin/product/productConfig/pageQueryCompanyInfo',
  },
]
