export default [
  {
    /**
     * 查询被白名单列表
     * http://10.222.160.160:8080/#/view/pX9N7Wzr
     */
    name: 'queryWhiteList',
    method: 'post',
    url: '/admin/productwhitelist/pagelist',
  },
  {
    /**
     * 单独发送邀请码
     * http://10.222.160.160:8080/#/view/Wz396n8R
     */

    name: 'sendInviteCode',
    method: 'post',
    url: '/admin/batchinvite/send',
  },
  {
    /**
     * 单独发送邀请码
     * http://10.222.160.160:8080/#/view/Wz396n8R
     */

    name: 'deleteInviteCode',
    method: 'post',
    url: '/admin/batchinvite/delete',
  },
  {
    /**
     * 单独发送邀请码
     * http://10.222.160.160:8080/#/view/P81QWg8q
     */

    name: 'updateProductWhite',
    method: 'post',
    url: '/admin/productwhitelist/update',
  },
  {
    /**
     * 新增白名单
     * http://10.222.160.160:8080/#/view/42GEarXW
     */
    name: 'addProductWhite',
    method: 'post',
    url: '/admin/productwhitelist/save',
  },
  {
    /**
     * 新增白名单,上传文件方式
     * http://10.222.160.160:8080/#/view/42GEarXW
     */
    name: 'addBatchProductWhite',
    method: 'post',
    url: '/admin/productwhitelist/saveall',
  },
  {
    /**
     * 查询白名单详情
     * http://10.222.160.160:8080/#/view/qXbRr1Xv
     */
    name: 'queryWhiteDetail',
    method: 'post',
    url: '/admin/productwhitelist/detail',
  },
  {
    /**
     * 分页查询邀请记录
     * http://10.222.160.160:8080/#/view/L2kEnBXP
     */
    name: 'queryInvitedRecord',
    method: 'post',
    url: '/admin/productwhitelist/record',
  },
  {
    /**
     * 批量发送邀请码
     * http://10.222.160.160:8080/#/view/pX9NjNzr
     */
    name: 'sendBatchInvite',
    method: 'post',
    url: '/admin/batchinvite/sendall',
  },
  {
    /**
     * 查询批次列表
     * http://10.222.160.160:8080/#/view/r2j4e6XG
     */
    name: 'queryBatchList',
    method: 'post',
    url: '/admin/batchinvite/pagelist',
  },
  {
    /**
     * 批量邀请码详情
     * http://10.222.160.160:8080/#/view/JzAKVy8p
     */
    name: 'queryBatchDetail',
    method: 'post',
    url: '/admin/batchinvite/detail',
  },
  {
    /**
     * 查询批量邀请白名单信息
     * http://10.222.160.160:8080/#/view/wXn6pVX9
     */
    name: 'queryBatchWhiteList',
    method: 'post',
    url: '/admin/productwhitelist/querypage',
  },
  {
    /**
     * 生成白名单邀请批次
     * http://10.222.160.160:8080/#/view/d2qY4r2w
     */
    name: 'saveBatchWhiteList',
    method: 'post',
    url: '/admin/batchinvite/saveall',
  },
]
