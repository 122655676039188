import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { changeTheme } from '@/utils/index.js'
import service from '@/service'
import flatRoute from './flat-route'
import extendRoute from './extend.router'
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const walkTree = function(tree, menus, funcMap) {
  tree.forEach(m => {
    if (m.children) {
      walkTree(m.children, menus, funcMap)
    }

    if (m.functionType !== 'button') {
      menus.push(m.functionCode)
    }

    funcMap[m.functionCode] = true
  })
}

const routes = [
  {
    // 首页
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('../views/main.vue'),
    children: [],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      needLogin: false,
    },
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      needLogin: false,
    },
    component: () => import('../views/login/Logout.vue'),
  },
]

function createRouter(base = '/', mode = 'history') {
  const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    mode,
    base,
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    // 设置主题
    let theme = store.getters.config?.primaryColor || 'default' || 'default'
    changeTheme(theme)

    if (from.fullPath === to.fullPath) {
      next()
      return
    }
    let userInfo = store.getters.userInfo
    if (userInfo.login === undefined) {
      try {
        let user = await service.queryAuth().executeSerial()
        userInfo = { ...user }
      } catch {
        userInfo = { login: false }
      }
    }
    store.dispatch('user/updateUser', userInfo)
    // }
    if (userInfo.login === true) {
      if (store.getters.productList.length === 0) {
        let data = await service.findProductCodeList().executeSerial()
        store.dispatch('system/setProductList', data.list)
      }

      //通过路由配置，来获取对应页面的字典[],给字典赋上默认值[]
      let obj = {}
      for (const key of to.meta.enum || []) {
        if (!store.getters.enumData[key]) {
          obj[key] = []
          store.commit('enum/SET_ENUMDATA', obj)
        }
      }

      let params = []
      for (const key in store.getters.enumData) {
        if (!store.getters.enumData[key].length) {
          params.push(key)
        }
      }
      params.length && store.dispatch('enum/getEnum', params)

      if (store.getters.menuTree === null) {
        let menu = await store.getters.config.common.queryMenu()
        let routes = [],
          menus = [],
          funcMap = {}
        walkTree(menu, menus, funcMap)
        routes = [...flatRoute, ...extendRoute].filter(m => {
          // 设置路由，可以覆盖，替换路由组件
          if (store.getters.config.configRoute) {
            m = store.getters.config.configRoute(m)
          }
          let funcCode = m.meta?.functionCode
          return funcCode ? funcMap[funcCode] : true
        })

        routes.forEach(m => {
          router.addRoute('index', m)
        })
        router.addRoute({
          path: '*',
          redirect: '/403',
        })
        store.dispatch('system/setMenu', { menus, tree: menu, funcMap })
        next({
          ...to,
          replace: true,
        })
      }
      if (to.name === 'login') {
        next({ name: 'home' })
      } else {
        next()
      }
    } else if (userInfo.login === false) {
      if (to.meta.needLogin === false) {
        next()
      } else {
        next({ path: '/login' })
      }
    }
    // if (store.getters.productList.length === 0) {
    //   let data = await service.findProductCodeList().executeSerial()
    //   store.dispatch('system/setProductList', data.list)
    // }
    // next()
  })

  router.afterEach(to => {
    store.dispatch('system/setRoute', to)
  })
  return router
}

export default createRouter
// eslint-disable-next-line no-undef
export const defaultRouter = createRouter(__ROUTERPATH__)

export function resetRouter() {
  const newRouter = createRouter()
  defaultRouter.matcher = newRouter.matcher
}
