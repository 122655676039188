export default [
  {
    /**
     * 放款分页查询列表
     *
     */
    name: 'queryBookLoanList',
    method: 'post',
    url: '/ledger/admin/loan/pagelist',
  },
  {
    name: 'queryBookLoanDetail',
    method: 'post',
    url: '/ledger/admin/loan/detail',
  },
  {
    name: 'queryBookSchedulelist',
    method: 'post',
    url: '/ledger/admin/loan/schedulelist',
  },
  {
    name: 'queryLedgerDetail',
    method: 'post',
    url: '/ledger/admin/queryLedgerDetail',
  },
  {
    /**
     * 还款分页查询列表
     *
     */
    name: 'querybookRepayList',
    method: 'post',
    url: '/ledger/admin/repay/pagelist',
  },
  {
    name: 'querybookRepayDetail',
    method: 'post',
    url: '/ledger/admin/repay/detail',
  },
  {
    name: 'querybookRepayLoanList',
    method: 'post',
    url: '/ledger/admin/repay/loanList',
  },
  {
    /**
     * 还款计划分页查询列表
     *
     */
    name: 'querybookLedgerList',
    method: 'post',
    url: '/ledger/admin/schedule/pagelist',
  },
  {
    name: 'queryBookFindProduct',
    method: 'post',
    url: '/ledger/admin/loan/findProduct',
  },
  {
    name: 'queryBookFindFund',
    method: 'post',
    url: '/ledger/admin/loan/findFund',
  },
]
