import tinycolor from './tinycolor.js';

var highPoints = [60, 180, 300];
var lowPoints = [0, 120, 240];
var hueStep = 2;
var saturationStep = 15;
var saturationStep2 = 2;
var brightnessStep1 = 2;
var brightnessStep2 = 15;
var middleVal = 6;

var getTargetPoint = function (H, arr) {
    var i, minAbs, minIndex, absArr = [];
    var len = arr && arr.length || 0;

    for (i = 0; i < len; i++) {
        absArr.push(Math.abs(arr[i] - H));
    }

    minAbs = absArr[0];
    minIndex = 0;

    for (i = 1; i < len; i++) {
        if (absArr[i] < minAbs) {
            minAbs = absArr[i];
            minIndex = i;
        }
    }

    return arr[minIndex];
};

var getStep = function (hsv) {
    if (!hsv) {
        return;
    }

    var hsv_s = hsv.s * 100;
    var hsv_v = hsv.v * 100;

    if (hsv_s > 95) {
        saturationStep = (hsv_s - 5) / 5;
    } else if (hsv_s > 30) {
        saturationStep = (hsv_s - 5) / 5;
        saturationStep2 = (100 - hsv_s) / 4;
    } else {
        saturationStep2 = (100 - hsv_s) / 4;
    }

    if (hsv_v > 95) {
        brightnessStep2 = (hsv_v - 30) / 4;
    } else if (hsv_v > 30) {
        brightnessStep1 = (100 - hsv_v) / 5;
        brightnessStep2 = (hsv_v - 30) / 4;
    } else {
        brightnessStep1 = (100 - hsv_v) / 5;
    }
};

var getH = function (H, isLight, i) {
    var targetH;
    var nearPoint;
    var isAdd;

    nearPoint = isLight ? getTargetPoint(H, highPoints) : getTargetPoint(H, lowPoints);
    isAdd = nearPoint - H > 0;
    targetH = isAdd ? H + hueStep * i : H - hueStep * i;

    if (isAdd) {
        targetH = targetH > nearPoint ? nearPoint : targetH;
    } else {
        targetH = targetH < nearPoint ? nearPoint : targetH;
    }

    return Math.round(targetH);
};

var getS = function (S, isLight, i) {
    var targetS = S;
    var SLightVal = S - saturationStep * i > 0 ? S - saturationStep * i : 0;
    var SDeepVal = S + saturationStep2 * i > 100 ? 100 : S + saturationStep2 * i;

    if (S > 95) {
        targetS = isLight ? SLightVal : 100;
    } else if (S > 30) {
        targetS = isLight ? SLightVal : SDeepVal;
    } else if (S > 0) {
        targetS = isLight ? S : SDeepVal;
    }

    return Math.round(targetS);
};

var getB = function (B, isLight, i) {
    var targetB = B;
    var BLightVal = B + brightnessStep1 * i > 100 ? 100 : B + brightnessStep1 * i;
    var BDeepVal = B - brightnessStep2 * i > 0 ? B - brightnessStep2 * i : 0;

    if (B > 95) {
        targetB = isLight ? 100 : BDeepVal;
    } else if (B > 30) {
        targetB = isLight ? BLightVal : BDeepVal;
    } else {
        targetB = isLight ? BLightVal : B;
    }

    return Math.round(targetB);
};

export default function colorPlate(color, index) {
    var curH, curS, curB;
    var isLight = index <= middleVal;
    var i = Math.abs(index - middleVal);
    var hsv = tinycolor(color).toHsv();

    getStep(hsv);
    curH = getH(hsv.h, isLight, i);
    curS = getS(hsv.s * 100, isLight, i);
    curB = getB(hsv.v * 100, isLight, i);

    // 特殊情况处理，当S为0时，H自动设置为0
    if (curS === 0) {
        curH = 0;
    }

    // 加深当B为0时，H、S自动变为0
    if (curB === 0 && !isLight) {
        curH = 0;
        curB = 0;
    }

    return tinycolor({
        h: curH,
        s: curS,
        v: curB,
    }).toHexString();
};