import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getStorage } from '@/utils'
Vue.use(VueI18n)

const modulesFiles = import.meta.globEager('../assets/lang/*.js')

const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
  const value = modulesFiles[modulePath]
  const locale = modulePath
    .split('/')
    .pop()
    .replace('.js', '')
  modules[locale] = value.default
  return modules
}, {})

// 通过选项创建 VueI18n 实例
export default new VueI18n({
  locale: getStorage('locale') || 'zh', // 设置地区
  messages: modules, // 设置地区信息
})
