export default [
  {
    /**
     * 查询企业信息列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryEnterpriseInfoList',
    method: 'post',
    url: '/admin/companyManagement/pageQueryList',
  },
  {
    /**
     * 查询企业账户信息详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCompany',
    method: 'post',
    url: '/admin/companyManagement/queryCompany',
  },
  {
    /**
     * 查询企业信息详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryEnterpriseInfoDetail',
    method: 'post',
    url: '/admin/companyManagement/queryCompanyDetail',
  },
  {
    /**
     * 添加企业信息
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'insertEnterpriseInfo',
    method: 'post',
    url: '/admin/companyManagement/saveCompanyInfo',
  },
  {
    /**
     * 修改企业信息
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'updateEnterpriseInfo',
    method: 'post',
    url: '/admin/user/update',
  },
  {
    /**
     * 用户信息
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryOperatorLists',
    method: 'post',
    url: '/admin/operator/operatorInfo',
  },
  {
    /**
     * 联系人信息
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'querycontactLists',
    method: 'post',
    url: '/admin/user/contact',
  },
  {
    /**
     * 查询企业实名信息列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryEnterpriseRealInfoList',
    method: 'post',
    url: '/admin/realname/pagelist',
  },
  {
    /**
     * 查询企业实名信息详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryEnterpriseRealInfoDetail',
    method: 'post',
    url: '/admin/realname/detail',
  },
  {
    /**
     * 查询企业历史的实名实名记录
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryEnterpriseRealInfoHistory',
    method: 'post',
    url: '/admin/realname/history',
  },
  {
    /**
     * 查询企业历史的实名实名记录
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'findCustomerName',
    method: 'post',
    url: '/admin/user/findCustomerName',
  },
]
