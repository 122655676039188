export default [
  {
    /**
     * 部门列表
     * http://10.222.160.160:8080/#/view/P81QoB8q
     */
    name: 'queryDepartmentList',
    method: 'post',
    url: '/admin/dept/pagelist',
  },
  {
    /**
     * 部门下拉列表
     * http://10.222.160.160:8080/#/view/Q2VOd18e
     */
    name: 'queryDepartmentOptions',
    method: 'post',
    url: '/admin/dept/list',
  },
  {
    /**
     * 新增部门
     * http://10.222.160.160:8080/#/view/kXOyMa2D
     */
    name: 'addDepartment',
    method: 'post',
    url: '/admin/dept/save',
  },
  {
    /**
     * 修改部门
     * http://10.222.160.160:8080/#/view/a85L0N2Z
     */
    name: 'updateDepartment',
    method: 'post',
    url: '/admin/dept/update',
  },
]
