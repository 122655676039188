export default [
  {
    /**
     * 用户信息
     * http://10.222.160.160:8080/#/view/B2l5b08D
     */
    name: 'queryUserAdmin',
    method: 'post',
    url: '/admin/useradmin/detail',
  },
  {
    name: 'queryuserOperator',
    method: 'post',
    url: '/admin/user/operator',
  },
  {
    name: 'queryUserFindList',
    method: 'post',
    url: '/admin/user/findList',
  },
  //用户管理 - 查询列表
  {
    name: 'queryPageQueryLoginAccList',
    method: 'post',
    url: '/admin/login/pageQueryLoginAccList',
  },
  // 用户管理 - 用户登录日志
  {
    name: 'queryPageQueryLoginLogList',
    method: 'post',
    url: '/admin/login/pageQueryLoginLogList',
  },
  //用户管理- 详情
  {
    name: 'queryPageQueryDetail',
    method: 'post',
    url: '/admin/login/queryLoginAccDetail',
  },
]
