export default [
  {
    /**
     * 产品编码 ID：nzDGLVXp
     * http://10.222.160.160:8080/#/view/nzDGLVXp
     */
    name: 'findProductCodeList',
    method: 'post',
    url: '/admin/product/findList',
  },
  // {
  //   /***
  //    * 产品列表查询 url(/admin/product/pagelist)
  //    * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6284bd1b1627a0096723813d
  //    */
  //   name: 'queryProductList',
  //   method: 'post',
  //   url: '/admin/product/queryPageList',
  // },
  // {
  //   /***
  //    * 产品列表查询 url(/admin/product/detail)
  //    * https://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6284bd2f1627a0096723813f
  //    */
  //   name: 'queryProductDetail',
  //   method: 'post',
  //   url: '/admin/product/queryProductDetail',
  // },
  // // {
  // //   /***
  // //    * 新增 url(/admin/product/save)
  // //    * https://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6284bd347a82f9036757c4a4
  // //    */
  // //   name: 'saveProduct',
  // //   method: 'post',
  // //   url: '/admin/product/saveProduct',
  // // },
  // {
  //   /***
  //    * 更新产品 url(/admin/product/update)
  //    * https://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6284bd3a1627a00967238141
  //    */
  //   name: 'updateProduct',
  //   method: 'post',
  //   url: '/admin/product/updateProduct',
  // },
  // {
  //   /**
  //    * 获取企业信息 url(/admin/product/queryCompanyData)
  //    * https://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=628c4cad1627a0096723829c
  //    */
  //   name: 'queryCompanyData',
  //   method: 'post',
  //   url: '/admin/product/queryCompanyData',
  // },
  {
    /**
     * 产品管理-产品管理优化-新增产品接口  url(/admin/product/productConfig/saveProduct)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a050ea7a82f9036757c8d2
     */
    name: 'saveProduct',
    method: 'post',
    url: '/admin/product/productConfig/saveProduct',
  },
  {
    /**
     * 产品管理-产品管理优化-新增产品配置接口 url(/admin/product/productConfig/updateProductConfig)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a161811627a0096723854c
     */
    name: 'updateProductConfig',
    method: 'post',
    url: '/admin/product/productConfig/updateProductConfig',
  },
  {
    /**
     * 产品管理--查询产品相关公司信息 url(/admin/product/productConfig/pageQueryCompanyInfo)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a161811627a0096723854c
     */
    name: 'pageQueryCompanyInfo',
    method: 'post',
    url: '/admin/product/productConfig/pageQueryCompanyInfo',
  },
  {
    /**
     * 产品管理--新增产品--查询产品所选择公司下的账户信息 url(/admin/product/productConfig/pageQueryCompanyAccountInfo)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a193231627a00967238554
     */
    name: 'pageQueryCompanyAccountInfo',
    method: 'post',
    url: '/admin/product/productConfig/pageQueryCompanyAccountInfo',
  },
  {
    /**
     * 产品管理--新增产品--更新产品配置状态 url(/admin/product/productConfig/updateProductConfigState)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a19b7e1627a00967238562
     */
    name: 'updateProductConfigState',
    method: 'post',
    url: '/admin/product/productConfig/updateProductConfigState',
  },
  {
    /**
     * 产品管理--新增产品--更新产品配置状态 url(/admin/product/productConfig/pageQueryProductConfig)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a3151c1627a0096723859e
     */
    name: 'pageQueryProductConfig',
    method: 'post',
    url: '/admin/product/productConfig/pageQueryProductConfig',
  },
  {
    /**
     * 产品管理--分页查询产品配置信息 url(/admin/product/pageQueryProductConfig)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a3151c1627a0096723859e
     */
    name: 'pageQueryProductConfig',
    method: 'post',
    url: '/admin/product/productConfig/pageQueryProductConfig',
  },
  {
    /**
     * 产品管理--新增产品--产品配置详情 url(/admin/product/queryProductConfigDetail)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62a6e3377a82f9036757f1ff
     */
    name: 'queryProductConfigDetail',
    method: 'post',
    url: '/admin/product/productConfig/queryProductConfigDetail',
  },
]
