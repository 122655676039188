import config from '@/config'
const modulesFiles = import.meta.globEager('./modules/*.js')
const overrideMap = config.overrideMap || {}
const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
  // const moduleName = modulePath.replace(/^\.\/modules\/(.*)\.\w+$/, '$1')
  const value = modulesFiles[modulePath]
  value.default.forEach(m => {
    if (overrideMap[m.name]) {
      m.url = overrideMap[m.name]
    }
    modules.push(m)
  })
  return modules
}, [])

export default modules
