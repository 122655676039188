export default [
  {
    /**
     * 授信统计
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCreditList',
    method: 'post',
    url: '/admin/access/queryCreditList',
  },
  {
    /**
     * 用信统计
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryLoanList',
    method: 'post',
    url: '/admin/finance/queryLoanList',
  },
  {
    /**
     * 还款统计
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryRepayList',
    method: 'post',
    url: '/admin/repay/queryRepayList',
  },
  {
    /**
     * 授信统计-总额度
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCreditNum',
    method: 'post',
    url: '/admin/access/queryCreditNum',
  },
  {
    /**
     * 用信统计-总额度
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryLoanNum',
    method: 'post',
    url: '/admin/finance/queryLoanNum',
  },
  {
    /**
     * 还款统计-客户数
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryRepayNum',
    method: 'post',
    url: '/admin/repay/queryRepayNum',
  },
  {
    /**
     * 授信统计-导出
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'exportCreditList',
    method: 'post',
    url: '/admin/access/exportCreditList',
  },
  {
    /**
     * 用信统计-导出
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'exportLoanList',
    method: 'post',
    url: '/admin/finance/exportLoanList',
  },
  {
    /**
     * 还款统计-导出
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'exportRepayList',
    method: 'post',
    url: '/admin/repay/exportRepayList',
  },
]
