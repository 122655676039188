export default [
  // 获取年份列表
  {
    name: 'holidayGetYear',
    method: 'post',
    url: '/admin/holiday/getYear',
  },
  // 获取当年的日期列表
  {
    name: 'holidayGetDate',
    method: 'post',
    url: '/admin/holiday/getDate',
  },
  // 修改节假日
  {
    name: 'holidayUpdateHoliday',
    method: 'post',
    url: '/admin/holiday/updateHoliday',
  },
]
