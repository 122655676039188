export default [
  {
    /**
     * 分页查询角色
     * http://10.222.160.160:8080/#/view/wXPDeeXn
     */
    name: 'queryRoleList',
    method: 'post',
    url: '/admin/role/pagelist',
  },
  {
    /**
     * 分页查询角色
     * http://10.222.160.160:8080/#/view/wXPDeeXn
     */
    name: 'queryRoleOptions',
    method: 'post',
    url: '/admin/role/list',
  },
  {
    /**
     * 查询角色详情
     * http://10.222.160.160:8080/#/view/wXPDeeXn
     */
    name: 'queryRoleDetail',
    method: 'post',
    url: '/admin/role/detail',
  },
  {
    /**
     * 查询功能树型下拉
     * http://10.222.160.160:8080/#/view/P81Qdm8q
     */
    name: 'queryTreeSelect',
    method: 'post',
    url: '/admin/function/queryTreeSelect',
  },
  {
    /**
     * 查询功能路由列表
     * http://10.222.160.160:8080/#/view/42GExmXW
     */
    name: 'queryRoute',
    method: 'post',
    url: '/admin/function/queryRoute',
  },
  {
    /**
     * 添加角色
     * http://10.222.160.160:8080/#/view/42GExmXW
     */
    name: 'addRole',
    method: 'post',
    url: '/admin/role/save',
  },
  {
    /**
     * 查询功能路由列表
     * http://10.222.160.160:8080/#/view/42GExmXW
     */
    name: 'updateRole',
    method: 'post',
    url: '/admin/role/update',
  },
  {
    /**
     * 查询功能路由列表
     * http://10.222.160.160:8080/#/view/42GExmXW
     */
    name: 'queryFunctionDetail',
    method: 'post',
    url: '/admin/function/detail',
  },
]
