export default [
  {
    /***
     * 产品的动态表单 url(/admin/dynamicForm/queryFormData)
     * https://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6284d76e1627a0096723815b
     */
    name: 'queryFormData',
    method: 'post',
    url: '/admin/dynamicForm/queryFormData',
  },
  {
    name: 'queryFindList',
    method: 'post',
    url: '/ledger/admin/loan/findList',
  },
  {
    name: 'queryDictionarlist',
    method: 'post',
    url: '/page/dictionary/itemslist',
  },
]
