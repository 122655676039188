import { isType } from '@/utils'
import PopperJS from '@/utils/popper'
import store from '@/store'
let zIndex = 1000
const stop = e => e.stopPropagation()
const getZIndex = () => zIndex++

export default {
  data() {
    return {
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageSizes: [10, 20, 50, 100],
        layout: '->,total, slot, prev, pager, next, sizes',
      },
      dataList: [],
      loading: false,
    }
  },
  methods: {
    async handleQuery() {
      this.pagination.pageNum = 1
      this.process()
    },
    async handleReQuery() {
      this.process()
    },
    async changePageSize(ps) {
      this.pagination.pageNum = 1
      this.pagination.pageSize = ps
      this.process()
    },
    async changePage(p) {
      this.pagination.pageNum = p
      this.process()
    },
    async process() {
      this.loading = true
      let page = { ...this.pagination }
      delete page.layout
      delete page.pageSizes
      delete page.total
      try {
        await this.doQuery({
          ...page,
        })
        // eslint-disable-next-line no-useless-catch
      } catch (e) {
        throw e
      } finally {
        this.loading = false
      }
    },
    handleGo(to) {
      this.$router.push(to)
      this.comparePath = to.path
    },
    async doQuery() {},
  },
}

export const searchMixins = {
  computed: {
    formPageCfg() {
      return null
    },
  },
  // 方法
  methods: {
    // 查询
    resetForm() {
      this.$refs.searchForm.resetFields()
      if (store.getters.config.search.doQueryWhenReset) {
        this.$nextTick(() => {
          this.handleQuery()
        })
      }
    },
    // 重置
    handleQuery() {
      this.$emit('query', this.formData)
    },
    async loadInitParams() {},
  },
  async mounted() {
    await this.loadInitParams()
  },
}

export const formMixins = {
  props: {
    customerJs: [String, Array],
    parentRef: Object,
    itemCode: String,
    itemName: String,
    itemType: String,
    isDisplay: Boolean,
  },
  methods: {
    handleChange(v) {
      if (this.parentRef) {
        if (this.customerJs) {
          let javascript = this.customerJs
          if (isType(javascript, 'string')) {
            javascript = [javascript]
          }
          let func = new Function(...javascript)
          func.apply(this.parentRef, [v])
        }

        this.parentRef.$emit('broadcast', 'change', { code: this.itemCode, value: v })
      }
    },
  },
}

/**
 * @param {HTMLElement} [reference=$refs.reference] - The reference element used to position the popper.
 * @param {HTMLElement} [popper=$refs.popper] - The HTML element used as popper, or a configuration used to generate the popper.
 * @param {String} [placement=button] - Placement of the popper accepted values: top(-start, -end), right(-start, -end), bottom(-start, -end), left(-start, -end)
 * @param {Number} [offset=0] - Amount of pixels the popper will be shifted (can be negative).
 * @param {Boolean} [visible=false] Visibility of the popup element.
 * @param {Boolean} [visible-arrow=false] Visibility of the arrow, no style.
 */
export const popupMixins = {
  props: {
    transformOrigin: {
      type: [Boolean, String],
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    boundariesPadding: {
      type: Number,
      default: 5,
    },
    reference: {},
    popper: {},
    offset: {
      default: 0,
    },
    visibleArrow: Boolean,
    arrowOffset: {
      type: Number,
      default: 0,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    popperOptions: {
      type: Object,
      default() {
        return {
          gpuAcceleration: false,
        }
      },
    },
  },

  data() {
    return {
      showPopper: false,
      currentPlacement: '',
    }
  },

  watch: {
    showPopper(val) {
      if (this.disabled) return
      val ? this.updatePopper() : this.destroyPopper()
    },
  },

  methods: {
    createPopper() {
      if (this.$isServer) return
      this.currentPlacement = this.currentPlacement || this.placement
      if (!/^(top|bottom|left|right)(-start|-end)?$/g.test(this.currentPlacement)) {
        return
      }

      const options = this.popperOptions
      const popper = (this.popperElm = this.popperElm || this.popper || this.$refs.popper)
      let reference = (this.referenceElm = this.referenceElm || this.reference || this.$refs.reference)

      if (!reference && this.$slots.reference && this.$slots.reference[0]) {
        reference = this.referenceElm = this.$slots.reference[0].elm
      }

      if (!popper || !reference) return
      if (this.visibleArrow) this.appendArrow(popper)
      if (this.appendToBody) document.body.appendChild(this.popperElm)
      if (this.popperJS && this.popperJS.destroy) {
        this.popperJS.destroy()
      }

      options.placement = this.currentPlacement
      options.offset = this.offset
      options.arrowOffset = this.arrowOffset
      this.popperJS = new PopperJS(reference, popper, options)
      this.popperJS.onCreate(() => {
        this.$emit('created', this)
        this.resetTransformOrigin()
        this.$nextTick(this.updatePopper)
      })
      if (typeof options.onUpdate === 'function') {
        this.popperJS.onUpdate(options.onUpdate)
      }
      this.popperJS._popper.style.zIndex = getZIndex()
      this.popperElm.addEventListener('click', stop)
    },

    updatePopper() {
      const popperJS = this.popperJS
      if (popperJS) {
        popperJS.update()
        if (popperJS._popper) {
          popperJS._popper.style.zIndex = getZIndex()
        }
      } else {
        this.createPopper()
      }
    },

    doDestroy(forceDestroy) {
      /* istanbul ignore if */
      if (!this.popperJS || (this.showPopper && !forceDestroy)) return
      this.popperJS.destroy()
      this.popperJS = null
    },

    destroyPopper() {
      if (this.popperJS) {
        this.resetTransformOrigin()
      }
    },

    resetTransformOrigin() {
      if (!this.transformOrigin) return
      let placementMap = {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
      }
      let placement = this.popperJS._popper.getAttribute('x-placement').split('-')[0]
      let origin = placementMap[placement]
      this.popperJS._popper.style.transformOrigin =
        typeof this.transformOrigin === 'string'
          ? this.transformOrigin
          : ['top', 'bottom'].indexOf(placement) > -1
          ? `center ${origin}`
          : `${origin} center`
    },

    appendArrow(element) {
      let hash
      if (this.appended) {
        return
      }

      this.appended = true

      for (let item in element.attributes) {
        if (/^_v-/.test(element.attributes[item].name)) {
          hash = element.attributes[item].name
          break
        }
      }

      const arrow = document.createElement('div')

      if (hash) {
        arrow.setAttribute(hash, '')
      }
      arrow.setAttribute('x-arrow', '')
      arrow.className = 'popper__arrow'
      element.appendChild(arrow)
    },
  },

  beforeDestroy() {
    this.doDestroy(true)
    if (this.popperElm && this.popperElm.parentNode === document.body) {
      this.popperElm.removeEventListener('click', stop)
      document.body.removeChild(this.popperElm)
    }
  },

  // call destroy in keep-alive mode
  deactivated() {
    this.$options.beforeDestroy[0].call(this)
  },
}
