import service from '@/service'

const state = {
  enumData: {},
  enumMap: {},
}

const mutations = {
  SET_ENUMDATA: (state, data) => {
    // for (const key in data) {
    //   state.enumData[key] = data[key]
    // }
    state.enumData = { ...state.enumData, ...data }
  },
  SET_ENUMMAP: (state, data) => {
    for (const key in data) {
      if (!state.enumMap.hasOwnProperty.call(key) && data[key].length) {
        state.enumMap[key] = data[key].reduce((accumulator, current) => {
          accumulator[current.itemCode] = current
          return accumulator
        }, {})
      }
    }
  },
}

const actions = {
  async getEnum({ commit }, params) {
    const data = await service.queryDictionarlist({ dictCode: params }).executeSerial()
    commit('SET_ENUMDATA', data)
    commit('SET_ENUMMAP', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
