export default [
  // 用信管理-还款申请单
  {
    name: 'queryFinanceRepayPageList',
    method: 'post',
    url: '/admin/optimizerepay/pagelist',
  },
  //用信管理-还款申请单详情
  {
    name: 'queryFinanceRepayPageDetail',
    method: 'post',
    url: '/admin/optimizerepay/detail',
  },
  {
    name: 'queryFinancCustomerInfo',
    method: 'post',
    url: '/admin/optimizefinance/customer/info',
  },
  {
    name: 'queryFinanceRepayCustomerInfo',
    method: 'post',
    url: '/admin/optimizerepay/customer/info',
  },
  {
    name: 'queryFinancCustomerAudit',
    method: 'post',
    url: '/admin/optimizefinance/customer/audit',
  },
  // 融资节点
  {
    name: 'queryFinanceSonItems',
    method: 'post',
    url: '/page/dictionary/son/items',
  },
  {
    name: 'queryFinanceFundname',
    method: 'post',
    url: '/admin/customerrole/fundname/list',
  },
]
