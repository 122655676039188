export default [
  {
    /**
     * 放款列表
     * http://10.222.160.160:8080/#/view/d2qxQRzw
     */
    name: 'queryFinancePageList',
    method: 'post',
    url: '/admin/optimizefinance/pagelist',
  },
  {
    /**
     * 放款详情
     *http://10.222.160.160:8080/#/view/VXEG632v
     */
    name: 'queryFinanceDetail',
    method: 'post',
    url: '/admin/optimizefinance/detail',
  },
  {
    /**
     * 导出excel ID：B2lYmL8D
     * http://10.222.160.160:8080/#/view/B2lYmL8D
     */
    name: 'exportFinance',
    method: 'get',
    url: '/admin/finance/export ',
  },
  {
    /**
     * 更具贷款单查还款列表
     * http://10.222.160.160:8080/#/view/qXBoNWzE
     */
    name: 'queryRepayListByFinanceNo',
    method: 'post',
    url: '/admin/finance/repaylist',
  },
  {
    name: 'queryPostloanProject',
    method: 'post',
    url: '/risk/api/postloan/project/search',
  },
  {
    name: 'queryPostloanProject',
    method: 'post',
    url: '/risk/api/postloan/project/search',
  },

  // 项目信息搜索
  {
    name: 'queryPostloanProject',
    method: 'post',
    url: '/risk/api/postloan/project/search',
  },

  //预警信息搜索
  {
    name: 'queryPostloanDanger',
    method: 'post',
    url: '/risk/api/postloan/warning/search',
  },
]
