export default [
  {
    /**
     * 分页查询岗位列表
     * http://10.222.160.160:8080/#/view/YX0jMk2x
     */
    name: 'queryPostList',
    method: 'post',
    url: '/admin/post/pagelist',
  },
  {
    /**
     * 部门下拉列表
     * http://10.222.160.160:8080/#/view/Q2VOd18e
     */
    name: 'queryPostOptions',
    method: 'post',
    url: '/admin/post/list',
  },
  {
    /**
     * 新增部门
     * http://10.222.160.160:8080/#/view/kXOyMa2D
     */
    name: 'addPost',
    method: 'post',
    url: '/admin/post/save',
  },
  {
    /**
     * 修改部门
     * http://10.222.160.160:8080/#/view/a85L0N2Z
     */
    name: 'updatePost',
    method: 'post',
    url: '/admin/post/update',
  },
]
