/**
 * 准入接口
 * */
export default [
  {
    /**
     * 准入节点 ID：nzDoVGXp
     * http://10.222.160.160:8080/#/view/nzDoVGXp
     */
    name: 'queryAccessFlowNode',
    method: 'post',
    url: '/admin/access/queryAccessFlowNode',
  },
  {
    /**
     * 准入详情页 ID：P81MYG8q
     * http://10.222.160.160:8080/#/view/P81MYG8q
     */
    name: 'queryAccessDetail',
    method: 'post',
    url: '/admin/access/detail',
  },
  {
    /**
     * 准入申请分页列表 ID：58pllv83
     * http://10.222.160.160:8080/#/view/58pllv83
     */
    name: 'queryAccessPageList',
    method: 'post',
    url: '/admin/access/pagelist',
  },
  {
    /**
     * 导出excel ID：B2lYmL8D
     * http://10.222.160.160:8080/#/view/B2lYmL8D
     */
    name: 'exportAccess',
    method: 'get',
    url: '/admin/access/export',
  },
]
