<script lang="jsx">
export default {
  name: 'ui-table',
  props: {
    columns: Array,
    list: Array,
    pagination: Object,
    loading: Boolean,
    emptyText: {
      type: String,
      default: 'list.noData',
    },
    size: String,
    width: Number,
    height: Number,
    fixWdith: Number
  },
  methods: {
    pageChange(args) {
      this.$emit('page-change', args)
    },
    sizeChange(args) {
      this.$emit('size-change', args)
    },
  },
  render() {
    let columns = this.columns.map(m => {
      if (m.render) {
        return (
          <fin-table-column
            min-width={m.width}
            width={m.fixWidth}
            fixed={m.fixed}
            align={m.align || 'left'}
            prop={m.dataIndex}
            show-overflow-tooltip={true}
            label={this.translate(m.name)}
            {...{
              scopedSlots: {
                default: props => {
                  return m.render(props)
                },
              },
            }}
          />
        )
      }
      return (
        <fin-table-column
          prop={m.dataIndex}
          fixed={m.fixed}
          width={m.fixWidth}
          min-width={m.width}
          show-overflow-tooltip={true}
          label={this.translate(m.name)}
        />
      )
    })
    columns.push(
      <div slot="empty" class="empty">
        <div class="empty-bg" />
        <p>{this.$slots.empty ? this.$slots.empty : this.translate(this.emptyText)}</p>
      </div>,
    )
    let page = (this.pagination && this.pagination.total) ? (
      <fin-pagination
        class="mt-20px -mr-10px"
        page-sizes={this.pagination.pageSizes}
        total={this.pagination.total}
        current-page={this.pagination.pageNum}
        on-current-change={this.pageChange}
        on-size-change={this.sizeChange}
        layout="total, ->,  prev, pager, next,  sizes"
      />
    ) : null

    return (
      <div class="ui-table">
        <fin-table
          width={this.width}
          height={this.height}
          data={this.list}
          size={this.size || this.config.tableSize}
          v-loading={this.loading}
          valign="top"
        >
          {columns}
        </fin-table>
        {this.pagination && this.pagination.total>0 ? page : null}
      </div>
    )
  },
}
</script>
<style lang="less">
.ui-table {
  .fin-table__body-wrapper {
    // min-height: 320px;
  }
  .empty {
    margin: 20px;
  }
  .empty-bg {
    background: url(../assets/images/empty@2x.png) no-repeat center;
    width: 208px;
    height: 160px;
    background-size: contain;
    margin: 0 auto 20px;
  }
}
</style>
