export const Regex = {
  /* eslint-disable-next-line no-useless-escape */
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=(?:.*?[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]))[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~\\]{8,20}$/,
  mobile: /^1[3-9][\d]{9}$/,
  email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  smsVerifyCode: /^[\d]{1,6}$/,
  // productCode: /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{1,10}$/,
  // productName: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\u4e00-\u9fa5])[a-zA-Z0-9\u4e00-\u9fa5]{1,50}$/,
  productCode: /^[A-Z0-9]{1,10}$/,
  productName: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,50}$/,
  // 身份证号
  idcard: /(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}[0-9Xx]$)/,
  // 数字和字母
  numberletter: /^[A-Za-z0-9]+$/,
  // 汉字和字母
  charactorletter: /^[A-Za-z\u4e00-\u9fa5]+$/,
}

export const validateIDCard = (rule, value, callback) => {
  if (rule.required) {
    !value && callback(rule.message)
  } else {
    !value && callback()
  }
  //地区码省级  @todo市级地级代码也可以枚举
  const provinces = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古', //华北两市三省
    21: '辽宁',
    22: '吉林',
    23: '黑龙江', //东北三省
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东', //华东一市六省
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南', //华南五省
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏', //西南一市四省
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆', //西北五省
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外',
  }

  const code = value.toString().toUpperCase()

  if (!/^[1-9]\d*$/.test(code.substring(0, 17))) {
    callback('身份证号前17位必须为正整数!')
  }
  if (!provinces[value.substr(0, 2)]) {
    callback('身份证号前六位地区码有误!')
  }

  if (
    !/19[2-9]\d{1}|[2-9]\d{3}/.test(Number(code.substring(6, 10))) ||
    Number(code.substring(6, 10)) > new Date().getFullYear()
  ) {
    callback('身份证号年份错误!')
  }

  if (!/^((0[1-9])|(1[0-2]))$/.test(code.substring(10, 12))) {
    callback('身份证号月份错误!')
  }

  if (!/^((0[1-9])|([1-2][0-9])|30|31)$/.test(code.substring(12, 14))) {
    callback('身份证号日期错误!')
  } else {
    if (/^((0[469])|11)$/.test(Number(code.substring(10, 12))) && Number(code.substring(12, 14)) == 31) {
      callback('身份证号日期错误，4、6、9、11月没有31日!')
    } else if (Number(code.substring(10, 12)) == 2) {
      if (
        (Number(code.substring(6, 10)) % 4 == 0 && Number(code.substring(6, 10)) % 100 != 0) ||
        Number(code.substring(6, 10)) % 400 == 0
      ) {
        if (Number(code.substring(12, 14)) == 29) {
          callback()
        } else {
          callback('2月润年是29天')
        }
      }
      if (Number(code.substring(12, 14)) == 28) {
        callback()
      } else {
        callback('2月平年是28天')
      }
    }
  }

  // 加权因子
  const weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

  const last = code.substring(17) // 最后一位

  const seventeen = code.substring(0, 17)

  // ISO 7064:1983.MOD 11-2 判断最后一位校验码是否正确
  const arr = seventeen.split('')

  let num = 0
  for (let i = 0; i < arr.length; i++) {
    num += arr[i] * weightFactor[i]
  }

  // 获取余数
  const resisue = num % 11
  const lastNo = checkCode[resisue]
  if (last !== lastNo) {
    callback('身份证号最后一位校验码错!')
  }
  if (value.length !== 18) {
    callback('身份证号为18位!')
  }
  callback()
}
