import './assets/css/ui-cover.less'
import logo from './assets/images/logo/nav-logo.png'
import loginLogo from './assets/images/logo/login-logo.png'
import homeLogo from './assets/images/logo/home-logo.png'
import { isJSON } from '@/utils'
import BaseList from './components/BaseList.vue'
import SearchLayout from './components/SearchLayout.vue'
import Breadcrumb from './components/Breadcrumb.vue'
import MainLayout from './components/MainLayout.vue'
import service from '@/service'
export default {
  isCanSwitch: true,
  entName: '运营管理系统',
  entLogo: logo,
  isBreadCrumb: true,
  tableSize: 'small',
  titleHide: false,
  specialModule: true, //宜发展专用组件
  collapseInvalid: true, //菜单栏不让自动折叠
  baseList: BaseList,
  searchLayout: SearchLayout,
  mainLayoutComponent: MainLayout, //主布局组件，仅当微前端情况下使用
  /**
   * 项目设置路由规则
   * @param {VueRoute} route 路由配置
   */
  configRoute(route) {
    if (route.name === 'home') {
      delete route.component
      route.redirect = '/business_board'
    }
    return route
  },
  /**登录页面配置 */
  login: {
    logo: loginLogo,
    style: { width: '86px' },
  },
  /**欢迎页面配置 */
  home: {
    logo: homeLogo,
    description: '欢迎登录金融业务运营管理系统',
  },
  nav: {
    // bgNav: '#191a24',
    // textNavColor: '#fff',
    // bgMenu: '#191a24',
    // textMenuColor: '#fff',
    // activeTextMenuColor: '#2c68ff',
    activeTextMenuColor: '#F0F4FF',
    arrowIcon: 'fin-icon-caret-left',
    breadComponent: Breadcrumb,
  },
  search: { labelPosition: 'top', buttonPosition: 'mt-0', doQueryWhenReset: true },
  common: {
    // 转换url
    convertUrl(params) {
      let linkUrl = ''
      const convert = function(url) {
        console.log(url, 'url')
        // eslint-disable-next-line no-undef
        if (__MODE__ !== 'release') {
          return `api${url}`
        } else {
          let urls = url.split('?')
          let base = urls[0]
          let params = urls[1]
          let code = base.replace(/\//g, '.').replace(/(^\s*)|(\s*$)/g, '')
          let search = new URLSearchParams(params)
          search.append('apiCode', `erp.yfz${code}`) //jd.test   || erp.yfz
          return `//jnos-api-inner.crxn.cn${base}?${search.toString()}`
        }
      }
      if (typeof params === 'object') {
        if (['queryUserId', 'jnosAuthUserView'].includes(params.name)) {
          linkUrl = params.url
        } else {
          linkUrl = convert(params.url)
        }
      } else {
        linkUrl = convert(params)
      }
      return linkUrl
    },
    // code码转换
    convertCode(data) {
      if (data.code == 0) {
        data.code = 1
      }
      return data
    },
    // 标的物判断来源隐藏修改按钮
    subjectMatterEditHide(data) {
      return data?.source == 'INTERFACE_B2B_SHOP' ? true : false
    },
    async queryMenu() {
      const isJSON = function(str) {
        if (typeof str == 'string') {
          try {
            JSON.parse(str)
            return true
          } catch (error) {
            return false
          }
        } else {
          return false
        }
      }
      const walkMenu = function(data) {
        data.forEach(item => {
          let dataPath = item.ext && isJSON(item.ext) ? JSON.parse(item.ext) : {}
          item['functionName'] = item.name
          item['path'] = dataPath && dataPath.path == '/' ? null : dataPath?.path
          item['functionCode'] = dataPath?.functionCode
          item['functionType'] = 'menu'
          if (item.children) {
            walkMenu(item.children)
          }
        })
      }
      let { uid } = await service.queryUserId({ userType: '' }).executeSerial()
      let menu = []
      let meniuList = await service.jnosAuthUserView({ appCode: 'b2boper', uid }).executeSerial()
      meniuList.forEach(el => {
        if (el.code == 'QYJR') {
          menu = el.children
        }
      })
      walkMenu(menu)

      return menu
    },
  },
  //产品配置
  product: {
    //产品展示的模块
    modules: ['basic', 'core', 'fund'],
    productMutexConfig: val => {
      let rst = {
        AVE_CAP_PLUS_INT: false,
        AVE_CAPITAL: false,
        INT_AND_CAP_CLEAR: false,
        INT_1_CAP_2: false,
      }

      if (val.length > 0) {
        rst.AVE_CAP_PLUS_INT = val.includes('INT_AND_CAP_CLEAR') || val.includes('INT_1_CAP_2')
        rst.AVE_CAPITAL = val.includes('INT_AND_CAP_CLEAR') || val.includes('INT_1_CAP_2')
        rst.INT_AND_CAP_CLEAR = !val.includes('INT_AND_CAP_CLEAR')
        rst.INT_1_CAP_2 = !val.includes('INT_1_CAP_2')
      }
      return rst
    },
    basic: {
      CG: {
        rate: ['rateType', 'yearRate', 'punishRateFloat'],
        access: ['potentialUserCheck', 'renewCreditTime', 'accessTimeoutDuration'],
        finance: [
          'minFainceAmount',
          'termUnit',
          'loanTimeLimit',
          'loanTimeRange',
          'holidayLoan',
          'loanTimeout',
          'orderPaymentValidity',
          'capitalPath',
        ],
        ledger: ['repayMode', 'containRoll', 'interestRule', 'holidayProlong'],
        repay: ['activeRepaymentChannels', 'activeRepayMinFainceAmount', 'activeRepayReversalSequence'],
      },
      YS: {
        rate: ['rateType', 'yearRate', 'punishRateFloat'],
        access: ['potentialUserCheck', 'renewCreditTime', 'accessTimeoutDuration'],
        finance: ['minFainceAmount', 'termUnit', 'loanTimeLimit', 'loanTimeRange', 'holidayLoan', 'loanTimeout'],
        ledger: ['repayMode', 'containRoll', 'interestRule', 'holidayProlong'],
        repay: [
          'activeRepaymentChannels',
          'activeRepayMinFainceAmount',
          'activeRepayReversalSequence',
          'statementRepayChannel',
          'balanceTransferChannel',
          'statementRepayReversalSequence',
        ],
      },
      XD: {
        access: ['potentialUserCheck', 'renewCreditTime', 'accessTimeoutDuration'],
        finance: ['loanTimeRange', 'holidayLoan', 'loanTimeout', 'channlCode', 'capitalPath'],
        xdParameter: ['miniTerm', 'longTerm', 'miniAmount', 'longAmount', 'billPattern', 'xdName', 'overtimeLimit'],
        trade: ['issued', /*'tradeIs', */ /*'sell', 'tradeType',*/ 'invoiceIs', /*'contractIs', */ 'contractNum'],
        settle: ['channlCode', 'settleMode'],
      },
    },
    coreCount: {
      CG: 1, //采购融资可添加核心企业数量
      YS: 1, //应收融资可添加核心企业数量
      XD: 100, //信单可添加核心企业数量
    },
    coreAccountCount: {
      CG: 100, //采购融资核心企业可添加账户数量
      YS: 0, //应收融资核心企业可添加账户数量
      XD: 0, //应收融资核心企业可添加账户数量
    },
    fundCount: {
      CG: 1, //采购融资可添加资金方企业数量
      YS: 1, //应收融资可添加资金方企业数量
      XD: 1, //应收融资可添加资金方企业数量
    },
    operationCount: {
      CG: 1, //采购融资可添加运营方企业数量
      YS: 1, //应收融资可添加运营方企业数量
      XD: 1, //应收融资可添加运营方企业数量
    },
    isShowOutCode: {
      CG: false, //采购融资是否显示外部客户编码
      YS: true, //应收融资是否显示外部客户编码
      XD: false, //采购融资是否显示外部客户编码
    },
  },
  // 潜客魔盒数据配置
  potential: {
    basicField: ['refreshTime', 'preAccessState', 'timeDimension', 'cooperationPeriod', 'riskMagicBox'],
    potentialCustomerBasicInfoVO(val, params) {
      const arr1 = [
        'outerUserNo',
        'customerName',
        'productCode',
        'customerType',
        'certNo',
        'contactName',
        'cellPhone',
        'legalName',
        'legalCertId',
        'accessState',
        'whiteListNo',
      ]
      for (const item of arr1) {
        params[item] = val[item]
      }
    },
    riskMagicBoxVO(val, params) {
      if (!isJSON(val['riskMagicBox'])) return
      params['riskMagicBox'] = val['riskMagicBox'] && val['riskMagicBox']
    },
  },
  //企业账户信息数据配置
  enterpriseAccountInfo: {
    //覆盖校验规则
    overrideRuleMap: {
      area: [],
    },
    basicField: [],
  },
  //准入管理
  access: {
    creditInAccessDetail: true,
  },
}

export * from './router'
