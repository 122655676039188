export default [
  {
    /***
     * 分页查询操作员列表
     * http://10.222.160.160:8080/#/view/42GE9QXW
     */
    name: 'queryOperatorList',
    method: 'post',
    url: '/admin/operator/pagelist',
  },
  {
    /***
     * 新增操作员
     * http://10.222.160.160:8080/#/view/e24P9L2L
     */
    name: 'addOperator',
    method: 'post',
    url: '/admin/operator/save',
  },
  {
    /***
     * 修改操作员
     * http://10.222.160.160:8080/#/view/r2j4d7XG
     */
    name: 'updateOperator',
    method: 'post',
    url: '/admin/operator/update',
  },
  {
    /***
     * 批量增加
     * http://10.222.160.160:8080/#/view/r2j4d7XG
     */
    name: 'addBatchOperator',
    method: 'post',
    url: '/admin/operator/saveall',
  },
  {
    /***
     * 删除操作员
     * http://10.222.160.160:8080/#/view/32QrBp80
     */
    name: 'deleteOperator',
    method: 'post',
    url: '/admin/operator/delete',
  },
  {
    /***
     * 操作员详情
     * http://10.222.160.160:8080/#/view/32QrBp80
     */
    name: 'operatorDetail',
    method: 'post',
    url: '/admin/operator/detail',
  },
]
