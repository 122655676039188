const state = {
  userInfo: {},
}

const mutations = {
  UPDATE_USER: (state, userInfo) => {
    state.userInfo = userInfo
  },
}

const actions = {
  updateUser({ commit }, userInfo) {
    commit('UPDATE_USER', userInfo)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
