export default [
  {
    /**
     * 查询潜客名单列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryPotentialcustomerList',
    method: 'post',
    url: '/admin/potentialcustomer/pagelist',
  },
  {
    /**
     * 添加潜客名单
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'savePotentialcustomer',
    method: 'post',
    url: '/admin/potentialcustomer/saveall',
  },
  {
    /**
     * 修改潜客名单
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'updatePotentialcustomer',
    method: 'post',
    url: '/admin/potentialcustomer/update',
  },
  {
    /**
     * 潜客名单详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'potentialcustomerDetail',
    method: 'post',
    url: '/admin/potentialcustomer/detail',
  },
  {
    /**
     * 潜客名单更新记录
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'potentialcustomerRecord',
    method: 'post',
    url: '/admin/potentialcustomer/record',
  },
]
