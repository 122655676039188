const getters = {
  userInfo: state => state.user.userInfo,
  menuTree: state => state.system.menuTree,
  productList: state => state.system.productList,
  currentRoute: state => state.system.currentRoute,
  currentPageEnum: state => state.enum.enumList,
  // 字典数据和对象
  enumMap: state => state.enum.enumMap,
  enumData: state => state.enum.enumData,
  config: state => state.system.config,
  outer: state => state.outer.outer,
}
export default getters
