export default [
  {
    /**
     * 分页查询收款账户列表
     * http://10.222.160.160:8080/#/view/nzdnmq8j
     */
    name: 'queryReceiptList',
    method: 'post',
    url: '/admin/receipt/pagelist',
  },
  {
    /**
     * 获取收款账户详情
     * http://10.222.160.160:8080/#/view/xXaR1pzo
     */
    name: 'queryReceiptDetail',
    method: 'post',
    url: '/admin/receipt/detail',
  },
  {
    /**
     * 修改收款账户
     * http://10.222.160.160:8080/#/view/m8wLqpXk
     */
    name: 'updateReceipt',
    method: 'post',
    url: '/admin/receipt/account/update',
  },
  {
    /**
     * 创建收款账户
     * http://10.222.160.160:8080/#/view/xXaR1pzo
     */
    name: 'addReceipt',
    method: 'post',
    url: '/admin/receipt/save',
  },

  {
    /**
     * 创建账户
     * http://10.222.160.160:8080/#/view/xXaR1pzo
     */
    name: 'addSingleAccountSave',
    method: 'post',
    url: '/admin/receipt/account/save',
  },
  {
    /**
     * 确认导入
     * http://10.222.160.160:8080/#/view/xXaR1pzo
     */
    name: 'addAccountSave',
    method: 'post',
    url: '/admin/receipt/account/doimport',
  },

  {
    /**
     * 确认导入收款账户
     * http://10.222.160.160:8080/#/view/xXaR1pzo
     */
    name: 'addBatchReceipt',
    method: 'post',
    url: '/admin/receipt/doimport',
  },
  {
    name: 'queryFinanceCusAccount',
    method: 'post',
    url: '/admin/receipt/financing/pagelist',
  },
  {
    name: 'queryFinanceCusDetail',
    method: 'post',
    url: '/admin/receipt/financing/detail',
  },
  {
    name: 'queryReceipCoreDetail',
    method: 'post',
    url: '/admin/receipt/core/detail',
  },
  {
    name: 'queryReceipCoretList',
    method: 'post',
    url: '/admin/receipt/core/pagelist',
  },
  {
    /**
     * 查询省市信息
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=61d3fcb595840a42e010a945
     */
    name: 'queryAddressList',
    method: 'post',
    url: '/page/dictionary/address/queryAddressList',
  },
  {
    name: 'addBatchCoreReceipt',
    method: 'post',
    url: '/admin/receipt/core/doimport',
  },
  {
    name: 'receiptCoreUpdate',
    method: 'post',
    url: '/admin/receipt/account/update',
  },
  {
    name: 'querysOperLog',
    method: 'post',
    url: '/admin/oper/queryOperLog',
  },
  {
    name: 'queryAccountList',
    method: 'post',
    url: '/admin/receipt/account/pagelist',
  },
  {
    name: 'queryAccountDetail',
    method: 'post',
    url: '/admin/receipt/account/detail',
  },
  {
    name: 'queryRelevancList',
    method: 'post',
    url: '/admin/receipt/respect/list',
  },
  {
    /**
     * 中信账户管理分页查询
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryZxAccountPageList',
    method: 'post',
    url: '/admin/account/pagelist',
  },
  {
    /**
     * 中信账户管理详情
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryZxAccountDetail',
    method: 'post',
    url: '/admin/account/detail',
  },
  {
    /**
     * 中信账户管理新增
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'addZxSaveAccount',
    method: 'post',
    url: '/admin/account/save',
  },
  {
    /**
     * 中信账户明细
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryZxAdminDetailed',
    method: 'post',
    url: '/admin/account/detail/pagelist',
  },
  {
    /**
     * 中信账户主体
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryMainAccount',
    method: 'post',
    url: '/admin/account/queryMainAccount',
  },
  {
    /**
     * 中信查询账户明细
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryPrincipalAccount',
    method: 'post',
    url: '/admin/account/queryPrincipalAccount',
  },
  {
    /**
     * 中信查询客户编码
     * http://mock.jdfmgt.com/interface/?projectId=6210989baf658e3b672dcb93
     */
    name: 'queryFindCustomer',
    method: 'post',
    url: '/admin/user/findCustomer',
  },
]
