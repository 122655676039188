<script lang="jsx">
import { isType } from '@/utils'

export default {
  computed: {
    breadItems() {
      return this.$route.meta && this.$route.meta.breads || []
    },
  },
  render() {
    return (
      <div class="breadcrumb-name-list">
        <div>
          {this.config.isBreadCrumb ? <fin-breadcrumb>
            {this.breadItems.map(m => {
            let path = m.path
            if(isType(path, 'function')){
              path = path(this.$route.params)
            }
            return m.path ? (
              <fin-breadcrumb-item to={{ path }}>{this.translate(m.title)}</fin-breadcrumb-item>
            ) : (
              <fin-breadcrumb-item>{this.translate(m.title)}</fin-breadcrumb-item>
            )
          })}
          </fin-breadcrumb> : null }
        </div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.breadcrumb-name-list {
  font-size: 14px;
  padding-left: 24px;
  font-weight: 500;
  ::v-deep .fin-breadcrumb {
    .fin-breadcrumb__item {
      .fin-breadcrumb__inner {
        color: #77798e;
      }
      .fin-breadcrumb__separator {
        color: #b4b5c1;
        margin: 0 12px;
      }
      &:last-child {
        .fin-breadcrumb__inner {
          color: #393c5a;
        }
      }
    }
  }
}
</style>
