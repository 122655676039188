/**请勿手动修改该文件 */
import Vue from 'vue'
import FinD from '@jdt/find'
import i18n from '@/utils/lang'
import Jdt from '@jdt/jdt-business'
import '@jdt/jdt-business/lib/index.css'
import '@/assets/css/index.less'
;[FinD, Jdt].forEach(c =>
  Vue.use(c, {
    i18n: (key, value) => i18n.t(key, value),
  }),
)
export { default, routeConfig } from './yfz/config'
