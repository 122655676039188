export default [
  {
    /**
     * 中海油授信列表
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=6321811a697edf484e5dfc07
     */
    name: 'queryApplyListForZhy',
    method: 'post',
    url: '/admin/cnooc/credit/pagelist',
  },
  {
    /**
     * 通过准入编号查询授信审核结果
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=6321b24a79621f424e8ae993
     */
    name: 'queryByAccessNoForZhy',
    method: 'post',
    url: '/admin/cnooc/credit/audit/queryByAccessNo',
  },
]
