const state = {
  menuTree: null,
  productList: [],
  currentRoute: null,
  config: null,
}

const mutations = {
  SET_MENU: (state, menu) => {
    state.menuTree = menu
  },
  SET_PRODUCT_LIST: (state, productList) => {
    state.productList = productList
  },
  SET_CURRENT_ROUTE: (state, route) => {
    state.currentRoute = route
  },
  SET_CONFIG: (state, config) => {
    state.config = config
  },
}

const actions = {
  setMenu({ commit }, menu) {
    commit('SET_MENU', menu)
  },
  setProductList({ commit }, productList) {
    commit('SET_PRODUCT_LIST', productList)
  },
  setRoute({ commit }, route) {
    commit('SET_CURRENT_ROUTE', route)
  },
  setConfig({ commit }, config) {
    commit('SET_CONFIG', config)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
