<script lang="jsx">
import { Input, DatePicker } from '@jdt/find'
import ExtFinCell from '@/components/extend/FinCell.vue'
import service from '@/service'
import { bus } from '@/utils/index.js'

const extSelect = {
  props: {
    value: [String, Number, Boolean, Array],
    options: {
      type: Array,
      default: () => [],
    },
    itemCode:String
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch:{
    options(){
      // this.list = this.options ? [...this.options] : []
      this.freshList()
    },
    deep: true,
  },
  mounted(){
    // this.list = this.options ? [...this.options] : []
    this.freshList()
  },
  data(){
    return {
      loading: false,
      list: this.options
    }
  },
  methods:{
    async remoteMethod(){},
    freshList(){
      this.list = this.options&&[...this.options]
    },
  },
  render() {
    return (
      <fin-select
        value={this.model}
        {...{ attrs: this.$attrs }}
        on-focus={this.remoteMethod.bind(this, '')}
        on-input={v => {
          this.model = v
        }}
        remoteMethod={this.remoteMethod}
      >
        {this.list.map(item => {
          return <fin-option key={item.value} label={this.itemCode=='productCode'?`${item.label}-${item.value}`:item.label} value={item.value}>
          {this.itemCode=='productCode'?
            <div>
              <span style="float: left;">{ item.label }</span>
              <span style="float: right; font-size: 12px; color: rgba(0, 0, 0, .65);">{ item.value }</span>
            </div>:''}</fin-option>
          })
        }
      </fin-select>
    )
  },
}

const cusSelect = {
  extends: extSelect,
  methods: {
    async remoteMethod(query) {
      let list = await service
        .findCustomerName({
          customerName: query,
        })
        .executeSerial()

      this.list = list.map(m => {
        return {
          label: m.customerName,
          value: m.customerNo,
        }
      })
    },
  },
}

export default {
  components: {
    ExtFinCell,
  },
  props: {
    value: Object,
    formPageCfg: Array,
    permissionKey: String,
  },
  data() {
    return {
      isClose: true,
      componentMap: {
        input: Input,
        select: extSelect,
        date: DatePicker,
        'cus-select': cusSelect
      },
      defaultProps: {
        // input: { placeholder: this.translate('common.enter') },
        // select: { placeholder: this.translate('common.select') },
        date: {
          endPlaceholder: this.translate('common.endPlaceholder'),
          rangeSeparator: this.translate('common.rangeSeparator'),
          startPlaceholder: this.translate('common.startPlaceholder'),
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd',
        },
        'cus-select': {
          filterable: true,
          remote: true,
        }
      },
      formData: {},
    }
  },
  computed: {
    displayText() {
      return this.isClose ? this.translate('common.open') : this.translate('common.close')
    },
    displayIcon() {
      return this.isClose ? 'fin-icon-arrow-down' : 'fin-icon-arrow-up'
    },
  },
  inject: ['permission', 'exportUrl'],
  methods: {
    handleExportNotice() {
      bus.$emit('handleExport')
    },
    switchClose() {
      this.isClose = !this.isClose
    },
    handleQuery() {
      this.$emit('query')
    },
    handleReset() {
      this.$emit('reset')
    },
    resetFields() {
      this.$refs.searchForm.resetFields()
    },
    initCfg() {
      this.$watch(
        'formData',
        val => {
          this.$emit('input', val)
        },
        {
          deep: true,
        },
      )
    },
    getFormItems() {
      if (this.$slots.default) {
        return this.$slots.default
          .filter(m => m.tag)
          .map((m, i) => {
            return this.isClose && i > 2 ? null : (
                {m}
            )
          })
      } else if (this.formPageCfg) {
        return this.formPageCfg.map((it) => {
          let com = this.componentMap[it.itemType]
          let defProps = this.defaultProps[it.itemType]
          let props = {...defProps, ...it.props}
          if(it.itemType == 'date'){
            let reg = eval('/时间|起止|起始/g')
            props.endPlaceholder = `${it.itemName.replace(reg, '')}${this.translate('common.endPlaceholder')}`
            props.startPlaceholder = `${it.itemName.replace(reg, '')}${this.translate('common.startPlaceholder')}`
          }
          return (
              <fin-form-item label="" prop={it.itemCode} style={it.itemType == 'date' ? 'width: 400px' : 'width: 192px'}>
                {
                  <com
                    value={this.formData[it.itemCode]}
                    placeholder={it.itemName}
                    on-input={v => {
                      this.formData[it.itemCode] = v
                    }}
                    itemCode={it.itemCode}
                    {...{ attrs: props }}
                    clearable
                  ></com>
                }
              </fin-form-item>
          )
        })
      } else {
        return []
      }
    },
  },
  mounted() {
    this.formData = this.value
    this.initCfg()
  },
  render() {
    let formItems = this.getFormItems()
    let left = formItems.length % 4
    let leftSpace = 12 - left * 3
    if (formItems.length > 3) {
      leftSpace = this.isClose ? 12 - (formItems.filter(m=>m).length * 3) : 12 - left * 3
    }

    let funcNode = (
      <fin-form-item label="">
        <div class={['text-left', { [this.config.search.buttonPosition]: leftSpace != 12 }]}>
          {/* formItems.length > 3 ? (
            <fin-button icon={this.displayIcon} type="text" on-click={this.switchClose.bind(this)}>
              {this.displayText}
            </fin-button>
          ) : null */}
          <fin-button v-permission={this.permissionKey} type="primary" on-click={this.handleQuery.bind(this)}>
            {this.translate('common.query')}
          </fin-button>
          <fin-button class="custom-reset-btn" on-click={this.handleReset.bind(this)}>{this.translate('common.reset')}</fin-button>
          {this.exportUrl ? <fin-button class="custom-reset-btn" v-permission={this.permission} on-click={this.handleExportNotice.bind(this)}>下载数据</fin-button> : null}
        </div>
      </fin-form-item>
    )
    let cells = [...formItems, funcNode]
    return (
      <fin-form label-width="128px" ref="searchForm" class="search-layout" inline={true} label-position={this.config.search.labelPosition} props={{ model: this.formData }}>
        {/* <fin-grid class="fin-grid-search" gap={[20, 16]}> */}
          {cells}
        {/* </fin-grid> */}
      </fin-form>
    )
  },
}
</script>

<style lang="less">
.fin-grid-search {
  .fin-form-item {
    margin-bottom: 0px;
  }
}
</style>
