export default [
  {
    /**
     * 公告管理--新增公告(url:/admin/notice/save)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62f21c5bf54b57a7351ffd58
     * @params data Object
     */
    name: 'saveNotice',
    method: 'post',
    url: '/admin/notice/save',
  },
  {
    /**
     * 公告管理--公告分页列表查询(url:/admin/notice/pageList)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62f21ee1855696ad35fc3cb8
     * @params data Object
     */
    name: 'queryNoticeList',
    method: 'post',
    url: '/admin/notice/pageList',
  },
  {
    /**
     * 公告管理--公告修改(url:/admin/notice/updateByNo)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62f225b2855696ad35fc3cbd
     * @params data Object
     */
    name: 'updateNotice',
    method: 'post',
    url: '/admin/notice/updateByNo',
  },
  {
    /**
     * 公告管理--公告发布(url:/admin/notice/releaseNotice)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62f22b0b855696ad35fc3cc2
     * @params data Object
     */
    name: 'releaseNotice',
    method: 'post',
    url: '/admin/notice/releaseNotice',
  },
  {
    /**
     * 公告管理--公告修改(url:/admin/notice/getInfo)
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62f222fff54b57a7351ffd5a
     * @params data Object
     */
    name: 'getNotice',
    method: 'post',
    url: '/admin/notice/detail',
  },
]
