const state = {
  outer: {},
}

const mutations = {
  SET_OUTER_DATA: (state, data) => {
    state.outer = data
  },
}

const actions = {
  async setOuterData({ commit }, data) {
    commit('SET_OUTER_DATA', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
