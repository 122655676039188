export default [
  {
    /**
     * 分页查询客户信息
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCustomerList',
    method: 'post',
    url: '/admin/customer/pagelist',
  },
  {
    /**
     * 客户下拉列表
     * http://10.222.160.160:8080/#/view/42G6K3zW
     */
    name: 'queryCustomerPageList',
    method: 'post',
    url: '/admin/customer/list',
  },
  {
    /**
     * 获取客户详情
     * http://10.222.160.160:8080/#/view/E8rpVvz0
     */
    name: 'queryCustomerDetail',
    method: 'post',
    url: '/admin/customer/detail',
  },
  {
    /**
     * 获取产品开通信息
     * http://10.222.160.160:8080/#/view/nzDEmZ2p
     */
    name: 'queryProductOpenList',
    method: 'post',
    url: '/admin/customer/openproduct',
  },
  {
    /**
     * 获取产品的使用信息
     * http://10.222.160.160:8080/#/view/K8MwKRzl
     */
    name: 'queryProductUseList',
    method: 'post',
    url: '/admin/customer/useproduct',
  },
  {
    name: 'queryDownloadAttach',
    method: 'post',
    url: '/admin/customer/download',
  },
  {
    /**
     * 查询资方列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCapitalList',
    method: 'post',
    url: '/admin/user/capital/pagelist',
  },
  {
    /**
     * 添加资金方
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'insertCapital',
    method: 'post',
    url: '/admin/user/capital/insert',
  },
  {
    /**
     * 修改资金方
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'updateCapital',
    method: 'post',
    url: '/admin/user/capital/update',
  },
  {
    /**
     * 查询资方详情
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCapitalDetail',
    method: 'post',
    url: '/admin/user/capital/detail',
  },
  {
    name: 'queryCusFinanceList',
    method: 'post',
    url: '/admin/user/pagelist',
  },
  {
    name: 'queryCusFinanceDetail',
    method: 'post',
    url: '/admin/user/detail',
  },
  {
    name: 'queryCusCoreList',
    method: 'post',
    url: ' /admin/user/core/list',
  },
  {
    name: 'queryCustomerInfo',
    method: 'post',
    url: '/admin/user/customerInfo',
  },
  {
    name: 'queryBankInfo',
    method: 'post',
    url: '/page/dictionary/items',
  },
  {
    /**
     * 查询核心企业列表
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'queryCoreEnterpriseList',
    method: 'post',
    url: '/admin/user/core/pagelist',
  },
  {
    /**
     * 查询核心企业添加
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'insertCoreEnterprise',
    method: 'post',
    url: '/admin/user/core/insert',
  },
  {
    /**
     * 查询核心企业修改
     * http://10.222.160.160:8080/#/view/nzdZrD8j
     */
    name: 'updateCoreEnterprise',
    method: 'post',
    url: '/admin/user/core/update',
  },
]
