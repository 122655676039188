export default [
  {
    /**
     * 登录
     * http://10.222.160.160:8080/#/view/P8170dzq
     */
    name: 'doLogin',
    method: 'post',
    url: '/oauth/applyToken',
  },
  {
    name: 'queryAuth',
    method: 'post',
    url: '/oauth/noAuth/queryAuthInfo',
  },
  {
    name: 'updatePwd',
    method: 'post',
    url: '/admin/login/updatePwd',
  },
  {
    /**
     * 找回密码
     */
    name: 'forgetUpdatePwd',
    method: 'post',
    url: '/admin/login/noAuth/forgetUpdatePwd',
  },
  {
    /**
     * 发送找回验证码
     */
    name: 'sendForgetPwdSms',
    method: 'post',
    url: '/admin/login/noAuth/sendForgetPwdSms',
  },
  {
    /**
     * 发送xiugai验证码
     */
    name: 'sendUpdatePwdSms',
    method: 'post',
    url: '/admin/login/sendUpdatePwdSms',
  },
  {
    /**
     * 发送修改登录手机号短信验证码
     * http://10.222.160.160:8080/#/view/P81Pvd2q
     */
    name: 'sendUpdateLoginPhoneVerifyCode',
    method: 'post',
    url: '/admin/login/sendUpdateLoginPhoneVerifyCode',
  },
  {
    /**
     * 发送新手机号短验确认
     * http://10.222.160.160:8080/#/view/YX097kzx
     */
    name: 'sendNewLoginPhoneVerifyCode',
    method: 'post',
    url: '/admin/login/sendNewLoginPhoneVerifyCode',
  },
  {
    /**
     * 校验当前登录手机号
     * http://10.222.160.160:8080/#/view/58pGZE23
     */
    name: 'verifyUpdateLoginPhone',
    method: 'post',
    url: '/admin/login/verifyUpdateLoginPhone',
  },
  {
    /***
     * 更新登录手机号
     * http://10.222.160.160:8080/#/view/nzdpADzj
     */
    name: 'updateLoginPhone',
    method: 'post',
    url: '/admin/login/updateLoginPhone',
  },
  {
    /**
     * 退出
     * http://10.222.160.160:8080/#/view/7zLQom2Q
     */
    name: 'loginOut',
    method: 'post',
    url: '/oauth/loginOut',
  },
  {
    /**
     * 查询权限
     */
    name: 'queryRoute',
    method: 'post',
    url: '/admin/function/queryRoute',
  },
  {
    /**
     * 操作员修改密码
     */
    name: 'updateAdminPwd',
    method: 'post',
    url: '/admin/login/updateAdminPwd',
  },
]
