<script lang="jsx">
import store from './store';

export default {
  render() {
    // eslint-disable-next-line no-undef
    return <div profile={__PRODUCTKEY__}><router-view layout-component={store.getters.config.mainLayoutComponent}></router-view></div>
  },
}
</script>
