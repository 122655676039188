import Vue from 'vue'
// import { defaultRouter } from './router'
import store from './store'
import i18n from '@/utils/lang'
import { mapGetters } from 'vuex'
import { formatCurrency, formatDate, enumFilter } from '@/utils'
import { Dialog } from '@jdt/find'
const baseURL = import.meta.env.VITE_API_URL
const DialogConstructor = Vue.extend(Dialog)

Vue.mixin({
  filters: { formatCurrency, formatDate, enumFilter },
  computed: {
    //enumData 所有字典{key:[]}，用key获取对应的字典[]
    ...mapGetters(['config', 'userInfo', 'productList', 'currentPageEnum', 'enumData', 'enumMap']),
    initProductCode() {
      return this.productList[0]?.productCode
    },
  },
  methods: {
    formatCurrency,
    formatDate,
    enumFilter,
    openDialog(vNode, params) {
      let instance = new DialogConstructor({
        router: this.$router,
        store,
        i18n,
        el: document.createElement('div'),
        propsData: {
          width: '352px',
          visible: true,
          closeOnClickModal: false,
          ...params,
        },
      })

      vNode.componentOptions.listeners = {
        ...vNode.componentOptions.listeners,
        close: () => {
          instance.visible = false
        },
      }

      instance.$on('close', () => (instance.visible = false))
      instance.$on('closed', () => instance.$el.parentNode.removeChild(instance.$el))
      instance.$slots.default = [vNode]

      document.body.appendChild(instance.$el)
    },
    translate(key) {
      let ks = key.split('.')
      if (!ks[ks.length - 1]) {
        return ''
      }
      let rst = this.$t(key)

      if (ks.length > 1 && rst === key) {
        return ''
      }

      return rst
    },
    // enumFilter(enumList, ks) {
    //   let res = enumList.find(item => item.itemCode == ks)
    //   if (res) {
    //     return res.itemName
    //   } else {
    //     return ks
    //   }
    // },

    // 获取字典，已在router/index.js，写了一个全局的调用
    async getEnum(params = []) {
      await this.$store.dispatch('enum/getEnum', params)
    },
    convertUrl(url, fileNo) {
      if (fileNo) {
        if (!store.getters.config.common.convertUrl) {
          return `${baseURL}api/${url}/${fileNo}`
        } else {
          url = `${url}?fileNo=${fileNo}`
          return `${store.getters.config.common.convertUrl(url)}`
        }
      } else {
        if (!store.getters.config.common.convertUrl) {
          return `${baseURL}api/${url}`
        } else {
          return `${store.getters.config.common.convertUrl(url)}`
        }
      }
    },
  },
})
