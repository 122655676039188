export default [
  {
    /***
     * 合同管理分页列表
     * http://10.222.160.160:8080/#/view/P81koBzq
     */
    name: 'queryContractPageList',
    method: 'post',
    url: '/admin/contract/pagelist',
  },
  {
    /***
     * 合同详情
     * http://10.222.160.160:8080/#/view/58pWgd23
     */
    name: 'queryContractDtail',
    method: 'post',
    url: '/admin/contract/detail',
  },
  {
    /***
     * 合同签约
     * http://10.222.160.160:8080/#/view/wXnBxYX9
     */
    name: 'queryContractNodeList',
    method: 'post',
    url: '/admin/contract/nodelist',
  },
  {
    /**
     * 功能描述: 还款 导出
     * http://10.222.160.160:8080/#/view/d8xlgP8g
     */
    name: 'exportContractList',
    method: 'get',
    url: '/admin/contract/export',
  },
  /**
   * 合同管理-合同签署记录列表
   *  http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=628d9cb11627a009672382ce
   */
  {
    name: 'getContractFilePagelist',
    method: 'post',
    url: '/admin/contractfile/pagelist',
  },
  /**合同管理-合同签署记录详情
   * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=628d9e6d7a82f9036757c65d
   */
  {
    name: 'getContractDetail',
    method: 'post',
    url: '/admin/contractfile/detail',
  },
]
