import Vue from 'vue'
import App from './App.vue'
import { defaultRouter } from './router'
import store from './store'
import i18n from '@/utils/lang'
import directive from './utils/directive'
import config from './config/index'
import Sub from './Sub.vue'
import './global.jsx'

store.dispatch('system/setConfig', config)
import 'virtual:windi.css'
import PublicCard from './views/components/PublicCard.vue'
Vue.component('PublicCard', PublicCard) //全局注册卡片组件

Vue.directive('permission', directive)
const vueOptions = {
  router: defaultRouter,
  store,
  el: '#app',
  i18n,
}

let instance = null

function render(props = {}) {
  return new Vue({
    ...vueOptions,
    ...props,
  })
}

if (!window.proxy?.__POWERED_BY_QIANKUN__) {
  window.root = instance = render({
    render: h => h(App),
  })
} else {
  /**
   * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
   * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
   */
  const bootstrap = function() {}
  /**
   * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
   *
   * productCode 在jnos注册的产品
   * codetitle 标题栏展示的
   * titlegatewayUrl jnos网关地址
   * gatewayAppCode jnos网关AppCode
   * imageDomain 图片服务域名前缀
   * loginDomain 登录域名  如: //sso-uat.crxn.cn
   * loginUrl 登录地址，拼接了returnUrl
   * logoutUrl 退出登录地址
   * logoUrl 全局LOGO地址
   * iconUrl 全局favicon.icn地址
   * logoutMethod 退出登录方法，入参是对应的returnUrl
   * loginMethod登录方法，入参是对应的returnUrl
   * noAuthPage无权限页地址
   *
   */
  const mount = function(props) {
    let outerData = props.CONSTANTS
    store.dispatch('outer/setOuterData', outerData)

    instance = render({
      render: h => h(Sub),
      ...props,
    })

    console.log(store.getters.outer.outer)
  }

  /**
   * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
   */
  const unmount = function() {
    debugger
    instance.$destroy()
    instance.$el.innerHTML = ''
    instance = null
  }

  /**
   * 可选生命周期钩子，仅使用 loadMicroApp（手动加载微应用） 方式加载微应用时生效
   */
  const update = function(props) {
    console.log('update props', props)
  }

  window.proxy.__LIFECYCLE__ = {
    bootstrap,
    mount,
    unmount,
    update,
  }
}
