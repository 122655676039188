export default [
  {
    /***
     * 待办页面元素 url: '/admin/process/task/taskPageFormData',
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62b453d0f54b57a7351ff596
     */
    name: 'taskPageFormData',
    method: 'post',
    url: '/admin/process/task/taskPageFormData',
  },
  {
    /***
     * 	待办结果提交 url: '/admin/process/task/executeTask',
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62b45370f54b57a7351ff594
     */
    name: 'executeTask',
    method: 'post',
    url: '/admin/process/task/executeTask',
  },
  {
    /***
     * 待办页面元素 url: '/admin/process/task/pageQueryTaskList',
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62b40d6cf54b57a7351ff563
     */
    name: 'pageQueryTodoTaskList',
    method: 'post',
    url: '/admin/process/task/pageQueryTodoTaskList',
  },
  {
    /***
     * 查询待办节点执行人名称 url: '/admin/process/task/queryTaskExecutorName',
     * http://mock.jdfmgt.com/interface/detail?projectId=61a08f6195840a42e010a1e4&interfaceId=62ba61d8f54b57a7351ff65b
     */
    name: 'queryTaskExecutorName',
    method: 'post',
    url: '/admin/process/task/queryTaskExecutorName',
  },
  {
    /***
     * 查询任务详情 url: '/admin/process/task/queryTaskDetail',
     * http://mock.jdfmgt.com/interface/detail?projectId=6210989baf658e3b672dcb93&interfaceId=62bdaeff855696ad35fc372b
     */
    name: 'queryTaskDetail',
    method: 'post',
    url: '/admin/process/task/queryTaskDetail',
  },
]
